import React from 'react'
import "../components/Footer.css"
import "../components/NavBar.css"
import inaesPNG from "../Imagenes/Logos/inaesPNG.png"
import itspa from "../Imagenes/Logos/itspa - copia.png"
import logoNodess from "../Imagenes/Logos//logoNodess - copia.png"
import sadelogo from "../Imagenes/Logos/sadelogo.png"

import { Link, NavLink } from "react-router-dom";



function Footer() {
    return (
        <footer className="footer">

            <div className="container">
                <div className="row">
                    <div className="footer-col">
                        <h4>NODESS</h4>
                        <ul>
                            <li><a href="./inicio">Inicio</a></li>
                            <li><a href="./nosotros">Nosotros</a></li>
                            <li><a href="./noticias">Noticias</a></li>
                            <li><a href="./LineasEstrategicas">Lineas Estratégicas</a></li>
                            <li><a href="./Alianzas">Alianzas</a></li>
                            <li><a href="./Osses">OSSES</a></li>
                            <li><a href="./Contactanos">Contactanos</a></li>
                        </ul>
                    </div>



                    <div className="footer-col">
                        <h4>Siguenos</h4>
                        <div className="social-links">
                            <a href="https://www.facebook.com/profile.php?id=100050519454292"><i className="fab fa-facebook-f"></i></a>
                            <a href="https://www.instagram.com/patzcuarozirahuen/"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="footer-col">
                        
                        <h4>Nuestros Colaboradores</h4>
                        <div className="partner-logos">
                            <img src={logoNodess} alt="Partner Logo 1" className='iconContainer' />
                            <img src={inaesPNG} alt="Partner Logo 2" className='iconContainer' />
                            <img src={sadelogo} alt="Partner Logo 3" className='iconContainer' />
                            <img src={itspa} alt="Partner Logo 4" className='iconContainer' />
                        </div>
                        </div>
                    </div>
                </div>
            
        </footer>


    );
}

export default Footer;