  import { BrowserRouter as Router, Routes, Route,  Navigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import Inicio from "./Screens/inicio/inicio";
import Nosotros from "./Screens/nosotros/Nosotros";
import Alianzas from "./Screens/alianzas/alianzas";
import Contactanos from "./Screens/contactanos/contactanos";
import Noticias from "./Screens/noticias/noticias";
import NewsList from "./Screens/noticias/NewsList";
import Osses from "./Screens/osses/osses";
import LineasEstrategicas from "./Screens/lineasestrategicas/lineasEstrategicas";
import Footer from "./components/Footer";
import Member1Info from "./Screens/nosotros/member1Info";
import Member2Info from "./Screens/nosotros/member2Info";
import Member3Info from "./Screens/nosotros/member3Info";
import Member4Info from "./Screens/nosotros/member4Info";
import Member5Info from "./Screens/nosotros/member5Info";
import Member6Info from "./Screens/nosotros/member6Info";
import noticia from './Screens/noticias/noticias'
import CulturaPaz from "./Screens/lineasestrategicas/CulturaPaz";
import EstrategiaFortalecimiento from "./Screens/lineasestrategicas/EstrategiaFortalecimiento";
import EmprendimientosColect from "./Screens/lineasestrategicas/EmprendimientosColect";
import Investigacion from "./Screens/lineasestrategicas/Investigacion";
import DivulgacionDifusion from "./Screens/lineasestrategicas/DivulgacionDifusion";


function App() {
  
  return (
    <div className="">
      <Router>
        <NavBar />
        <Routes>
          <Route path="/inicio" element={<Inicio/>} />
          <Route path="/Nosotros" element={<Nosotros />} />
          <Route path="/noticias" element={<NewsList />} />
          <Route path="/LineasEstrategicas" element={<LineasEstrategicas />}>
            <Route index element={<EstrategiaFortalecimiento />} />
            <Route path="EstrategiaFortalecimiento" element={<EstrategiaFortalecimiento />} />
            <Route path="DivulgacionDifusion" element={<DivulgacionDifusion />} />
            <Route path="CulturaPaz" element={<CulturaPaz />} />
            <Route path="EmprendimientosColect" element={<EmprendimientosColect />} />
            <Route path="Investigacion" element={<Investigacion />} />
          </Route>
          <Route path="/Alianzas" element={<Alianzas />} />
          <Route path="/Osses" element={<Osses />} />
          <Route path="/Contactanos" element={<Contactanos />} />
          <Route path="/member1info" element={<Member1Info />} />
          <Route path="/member2info" element={<Member2Info />} />
          <Route path="/member3info" element={<Member3Info />} />
          <Route path="/member4info" element={<Member4Info />} />
          <Route path="/member5info" element={<Member5Info />} />
          <Route path="/member6info" element={<Member6Info />} />
          <Route path="/noticia" element={<Noticias />} />
          <Route path="/" element={<Navigate to="/inicio" />} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
