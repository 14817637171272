import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/generalStyles.css";
import sora from '../../Imagenes/Nosotros/Integrantes/soraida.jpg'

function Member5Info() {
    return (
        /**Seccion  */
        <div className='container'>
            <h2 className="h1 tituloCentrado">Sobre mi</h2>

            <section className='section'>
                <div className='imageContainer'>

                    <img src={sora} alt="Imagen de la noticia" className='newsImage' />
                    <p className='justifiedText pStyle'>Dra. Soraida Quezada Ascencio</p>
                    <div>
                        <h3 className="h1 tituloCentrado ">Formación Académica</h3>

                        <p className='justifiedText pStyle'>La docente investigadora es licenciada en Sistemas 
                        Computacionales y posee una Maestría en Ciencias de la Computación, así como un Doctorado
                         en Ciencias de la Educación.</p>
                    </div>
                </div>
                <div className='textContainer' >
                    <h2 className="h1 tituloCentrado ">Logros</h2>
                    <p className='justifiedText pStyle'>Fue encargada del club de robótica del plantel desde 2012 
                    hasta 2014, y desempeñó el papel de jefa de la carrera de Ingeniería en Tecnologías de la 
                    Información y Comunicaciones (TIC) de 2015 a 2018. Actualmente, participa como secretaria de 
                    la academia de Ingeniería en TIC. Ha colaborado en eventos académicos como FLISOL y Semana de 
                    la Ingeniería en TIC, y ha participado como jurado en concursos de robótica y en InnovaTecNM en 
                    2022. Además, forma parte del equipo operativo del NODESS Pátzcuaro-Zirahuén, donde apoya el área
                     de TIC's.
                    </p>
                    <h2 className="h1 tituloCentrado ">Experiencias</h2>

                    <p className='justifiedText pStyle'>La docente imparte asignaturas relacionadas con tecnologías, 
                    tales como sistemas operativos, taller de investigación, matemáticas discretas, administración 
                    de proyectos y bases de datos, entre otras. También participa en proyectos de investigación 
                    relacionados con el desarrollo de software.
                    </p>

                    <div>
                        <h2 className="h1 tituloCentrado ">Contacto</h2>
                        <p className='justifiedText pStyle'>squezada@itspa.edu.mx</p>
                    </div>
                </div>
            </section>
        </div>

    )
}



export default Member5Info;