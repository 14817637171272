import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/generalStyles.css";
import "../../../src/styles/BannerStyle"
import "../inicio/inicio.css";
import "../../components/carouselCards.css"
import "../../styles/video.css"
import "../../components/cardStyles.css"
import jak from '../../Imagenes/cooperativas/jak_quechas_urichas.jpg'
import laure from '../../Imagenes/cooperativas/laure.jpg'
import kat from '../../Imagenes/cooperativas/Katsukua-uri.jpg'
import oro from '../../Imagenes/cooperativas/bordadosOro.jpg'
import xara from '../../Imagenes/cooperativas/Xaratanga-curicuaueri.jpg'
import pie from "../../Imagenes/alianzas/pies Agiles.jpeg"
import bienestar from "../../Imagenes/alianzas/Bienestar.jpg"
import ario from "../../Imagenes/alianzas/ariosoales.jpeg"
import crs from "../../Imagenes/alianzas/CRS_2.jpeg"
import eronga from "../../Imagenes/alianzas/eronga.jpeg"
import patzcuaro from "../../Imagenes/alianzas/patzcuaro.png"
import tzintzuntzan from "../../Imagenes/alianzas/tzintzuntzan.png"
import EE from "../../Imagenes/alianzas/emprendedor.png"
import SADR from "../../Imagenes/alianzas/Agricultura.jpeg"
import migrante from "../../Imagenes/alianzas/migrante.jpeg"
import Banner from '../../components/Banner';
import mora from '../../Imagenes/cooperativas/Mora.jpg'
import nA2 from '../../Imagenes/noticias/noticia_A2.jpg'
import nA3 from '../../Imagenes/noticias/nA3.jpg'
import n1 from '../../Imagenes/noticias/n1.jpg'
import anuncio from "../../Videos/anuncio.mp4"
import Imagen1 from '../../Imagenes/Inicio/Imagen1.jpg'
import Imagen2 from '../../Imagenes/Inicio/imagen2.jpg'
import artesania from '../../Imagenes/Inicio/artesania.jpg'
function Inicio() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Habilita la reproducción automática
        autoplaySpeed: 3000, // Establece la velocidad de cambio en milisegundos (ejemplo: 3000 ms = 3 segundos)
    };

    const settingsCards = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Mostrar tres cartas a la vez
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        vertical: false, // Configura el carrusel para desplazarse horizontalmente
        responsive: [
            {
                breakpoint: 844, // Cambiar el número de cartas a mostrar en pantallas más pequeñas
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                },

            },
            {
                breakpoint: 400, // Cambiar el número de cartas a mostrar en pantallas más pequeñas
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <div className='containerMain'>

            <section className='containerCarrusel'>
                <Slider {...settings}>
                <div className="newsItem">
                            <img
                                src={Imagen1}
                                alt="Imagen de la noticia"
                                className="icon" />
                        </div>
                        <div className="newsItem">
                            <div className="newsImageContainer">
                                <img
                                    src={Imagen2}
                                    alt="Imagen de la noticia"
                                    className="newsImage" />
                            </div>
                        </div>


                </Slider>
            </section>

            <section class="video-container">
                <video controls>
                    <source src={anuncio} type="video/mp4"/>
                        Tu navegador no soporta el elemento de video.
                </video>
            </section>

            <section className="section">
                <div className='imageContainer'>
                    <img src={artesania} alt="Imagen" className='image' />

                </div>
                <div className='textContainer'>
                    <div className=''>
                        <h1 className='tituloPagina colortxt2'>¿Qué son los NODESS?</h1>
                    </div>
                    <p className='parrafosNA'>Los nodos de impulso a la economía social
                        y solidaria (NODESS), son alianzas locales integradas de manera
                        voluntaria, con al menos tres actores institucionales interesados
                        en fomentar y desarrollar la economía social y solidaria en el
                        territorio.</p>
                    <div className='buttonContainer'>
                        <button className='bn3637 bn37' onClick={handleButtonClicknosotros}>Ver más...</button>
                    </div>
                </div>
            </section>

            <section className='section2 color3'>
                <div className="tituloCentrado">
                    <h2 className="tituloPagina  colortxt6">Lineas Estratégicas</h2>
                </div>
                <div className="sectionCardsContainer">
                    <Slider {...settingsCards}>

                        <button className='bnCard bnCardColor cardItem '  onClick={handleButtonClickDifusion}>
                        
                            <img src="divulgacionIMG.jpg" alt="Imagen" className='iconCard' />
                            <div className='overLay-textCard'>
                                <h2 class="card-title">Divulgación y Difusión</h2>
                                <p class="card-description">Son Acciones que comuniquen y promuevan a la Economía Social y Solidaria, sus principios, valores y prácticas,.</p>
                            </div>
                        </button>

                        <button className='bnCard bnCardColor cardItem' onClick={handleButtonClickCultura}>
                            <img src="culturadepazIMG.jpg" alt="Imagen" className='iconCard' />
                            <div className='overLay-textCard'>
                                <h2 class="card-title">Cultura de Paz</h2>
                                <p class="card-description">Son Acciones que comuniquen y promuevan a la Economía Social y Solidaria, sus principios, valores y prácticas,.</p>
                            </div>
                        </button>

                        <button className='bnCard bnCardColor cardItem' onClick={handleButtonClickFortalecimiento}>
                            <img src={artesania} alt="Imagen" className='iconCard' />
                            <div className='overLay-textCard'>
                                <h2>Estrategia De Fortalecimiento De Capacidades</h2>
                                <p>Las acciones de diagnóstico y capacitación se centran en las necesidades de la ESS y cooperativas. Brindan herramientas para impulsar capacidades empresariales, tecnológicas e innovadoras. Además, apoyan valores y principios.</p>
                            </div>
                        </button>

                        <button className='bnCard bnCardColor cardItem' onClick={handleButtonClickEmprendimiento}>
                            <img src="emprendimientocolectivo.jpg" alt="Imagen" className='iconCard' />
                            <div className='overLay-textCard'>
                                <h2>Emprendimientos Colectivos</h2>
                                <p>NODESS te brinda la ayuda a que formes tu Cooperativa, contamos con cooperativas en formación, y contamos con programas para la creación de incubación de empresas de ESS.</p>
                            </div>
                        </button>

                        <button className='bnCard bnCardColor cardItem' onClick={handleButtonClickInvestigación}>
                            <img src="investigacionIMG.jpg" alt="Imagen" className='iconCard' />
                            <div className='overLay-textCard'>
                                <h2>Investigación</h2>
                                <p>NODESS realiza acciones para estudiar, interpretar y aplicar soluciones a problemáticas mediante métodos científicos adecuados al contexto social, generando conocimiento.</p>
                            </div>
                        </button>
                    </Slider>
                </div>
            </section>



            <section className="section2 ">
                <div className="tituloCentrado">
                    <h2 className="tituloPagina tituloCentrado colortxt2"> Alianzas</h2>
                </div>
                <p className='parrafosNA colortxt3'>Los NODESS son alianzas locales, voluntarias y autónomas, interesadas en fomentar e implementar acciones concretas de Economía Social y Solidaria (ESS) en el territorio.
                    Las alianzas NODESS se integran, por lo menos, con tres actores representativos de los sectores económico social, académico y gubernamental, como lo son:
                </p>
                <div className='contenedor-padre'>
                    <button className='imageSubContainer color9  bn37'>
                        <div className='imageCardContainer'>
                            <img src={crs} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>CRS</h3>
                            <p className='parrafosNA colortxt3'>
                                Catholic Relief Services en México trabaja para mejorar la calidad de vida de las
                                personas vulnerables y marginadas, así como para promover el desarrollo sostenible
                                y la justicia social en el país.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickCRS}>Ver más...</button>
                        </div>
                    </button>

                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={EE} alt="Icono 3" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Espacio Eprendedor</h3>
                            <p className='parrafosNA colortxt3'>Programa que forma parte del gobierno del estado de Michoacán, ubicado en la Avenida Lázaro Cárdenas de la ciudad de Morelia. Su principal objetivo de este organismo es fortalecer a las empresas, mediante estrategias de incubación, asesoría de registro de marcas y patentes.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickEmp}>Ver más...</button>
                        </div>
                    </div>

                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={SADR} alt="Icono 3" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Secretaria de Agricltura y Desarrollo Rural</h3>
                            <p className='parrafosNA colortxt3'>
                                Es la entidad encargada de formular y ejecutar políticas, programas y proyectos relacionados
                                con el desarrollo del sector agrícola, rural y alimentario en el estado de Michoacán.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickAgric}>Ver más...</button>
                        </div>
                    </div>

                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={bienestar} alt="Icono 3" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia '>Secretaria del Bienestar</h3>
                            <p className='parrafosNA colortxt3'>Es una iniciativa gubernamental que busca mejorar las condiciones de vida de la
                                población michoacana, especialmente de aquellos en situación de vulnerabilidad.
                                Este programa abarca diversas áreas, como salud, educación, vivienda, alimentación,
                                empleo y desarrollo social.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickBie}>Ver más...</button>
                        </div>
                    </div>

                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={ario} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Ayuntamiento de Ario</h3>
                            <p className='parrafosNA colortxt3'>
                                El Ayuntamiento de Ario de Rosales es la autoridad municipal encargada de administrar y
                                gobernar el municipio de Ario de Rosales. Su sitio web puede ofrecer información sobre
                                servicios municipales, trámites administrativos, noticias y eventos locales, así como
                                datos relevantes sobre la gestión y el gobierno municipal.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickAri}>Ver más...</button>
                        </div>
                    </div>

                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={patzcuaro} alt="Icono 2" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Ayuntamiento de Pátzcuaro</h3>
                            <p className='parrafosNA colortxt3'>
                                Sitio web para encontrar información sobre servicios municipales,
                                trámites administrativos,
                                noticias locales, eventos comunitarios, proyectos de desarrollo, así como datos históricos,
                                culturales y turísticos sobre Pátzcuaro.
                            </p>
                        </div>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickPat}>Ver más...</button>
                        </div>
                    </div>

                    
                </div>
                <div className='buttonContainer'>
                    <button className='bn2 bn7' onClick={alianzasPage}>Ver Todo...</button>
                </div>

            </section>

            <section className="section2 ">
                <div className="tituloCentrado">
                    <h2 className="tituloPagina tituloCentrado colortxt2">Cooperativas</h2>
                </div>
                <p className='parrafosNA colortxt3'>Las OSSES, siglas que significan "Organismos de la Sociedad Civil
                    de Interés Público", son instituciones no gubernamentales que tienen un interés en mejorar
                    y contribuir al desarrollo social y comunitario. Estas organizaciones suelen estar compuestas
                    por ciudadanos que comparten un objetivo común en áreas como la educación, la salud, el medio
                    ambiente, el desarrollo económico, entre otros.
                </p>
                <div className='contenedor-padre'>
                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={mora} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Esperanza Artesanal "La Mora"</h3>
                            <p className='parrafosNA colortxt3'>
                                La cooperativa se dedica al diseño, elaboración y
                                comercialización de artesanías de huinumo y textiles, de la comunidad de Zarzamora,
                                Michoacán.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickmora}>Ver más</button>
                        </div>
                    </div>

                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={xara} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Xaratanga Curicuaueri</h3>
                            <p className='parrafosNA colortxt3'>
                                La coopertiva Xaratanga Curicuaeri son un grupo emprendedores
                                que se dedica a la elaboración artesanías, de la comunidad de Erongarícuaro, Michoacán.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickxara}>Ver más</button>
                        </div>
                    </div>

                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={laure} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Tsitsiki Laure</h3>
                            <p className='parrafosNA colortxt3'>
                                La cooperativa Tsitsiki Laure es un grupo de mujeres de la
                                comunidad de San Miguel Nocutzepo que elaboramos a mano distintas prendas para la
                                vestimenta y el hogar. Fomentando la cultura de bordado a nuestros hijos, la cooperativa
                                te puede realizar prendas personalizadas.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicklaure}>Ver más</button>
                        </div>
                    </div>

                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={jak} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Jak Quechas Urichas</h3>
                            <p className='parrafosNA colortxt3'>
                                Jak quechas Urichas somos un grupo de artesanas de San Francisco
                                Uricho dedicadas a la creación de prendas artesanales hechas a mano, buscamos ser tu mejor
                                opción.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickjak}>Ver más</button>
                        </div>
                    </div>

                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={kat} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Katsujua Uri</h3>
                            <p className='parrafosNA colortxt3'>
                            Somos artesanos dedicados a elaborar sombreros artesanales en la comunidad de Jarácuaro, Michoacán.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickkat}>Ver más</button>
                        </div>
                    </div>

                    <div className='imageSubContainer color9 bn37'>
                        <div className='imageCardContainer'>
                            <img src={oro} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Bordados de Oro</h3>
                            <p className='parrafosNA colortxt3'>Somos artesanos dedicads a la cultura, tradiciones y bordados
                                artesanales de la comunidad de Tzintzuntzan, Michoacán.</p>
                        </div>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickoro}>Conocer más...</button>
                        </div>
                    </div>
                </div>

                <div className='buttonContainer'>
                    <button className='bn2 bn7' onClick={cooperativasPage} >Ver Todo...</button>
                </div>
            </section>

            <section className="sectionNoticias">
                <div className="sectionNoticiasContainer">
                    <h1 className="tituloPagina tituloCentrado colortxt2">Últimas Noticias</h1>
                    <Slider {...settings}>
                        <article className="newsItem">
                            <div className="newsImageContainer">
                                <img
                                    src={n1}
                                    alt="Imagen de la noticia"
                                    className="newsImage"
                                />
                            </div>
                            <div className="newsTextContainer">
                                <h3 className="tituloNoticia">Visita de los alumnos del CECYTEM 25 Opopeo al Intituto Tecnologico Superior de Pátzcuaro</h3>
                                <p className="parrafoF">Fecha: 11/04/2024</p>
                                <p className="parrafosNA">
                                    Hoy recibimos en el Instituto Tecnológico Superior de Pátzcuaro
                                    con alegría la visita de los alumnos del CECYTEM 25 Opopeo, en nuestro taller de EmprendeTEC
                                    en Administración donde la Ingeniería de Administración está marcando la diferencia.</p>

                                <div className="buttonContainer">
                                    <button className="bn3637 bn37" onClick={handleButtonClicknoticiaultima}>Leer más...</button>
                                </div>
                            </div>
                        </article>

                        <article className="newsItem">
                            <div className="newsImageContainer">
                                <img
                                    src={nA2}
                                    alt="Imagen de la noticia"
                                    className="newsImage"
                                />
                            </div>
                            <div className="newsTextContainer">
                                <h3 className='tituloNoticia'>Cosas que debes de saber con el eclipse</h3>
                                <p className='parrafoF'>Fecha: 8/04/2024</p>

                                <p className='parrafosNA'>
                                    ¡Es hoy, es hoy! Antes de que llegue el momento más esperado, esto es lo que NO hay que hacer durante el eclipse
                                </p>
                                <div className='buttonContainer'>
                                    <button className='bn3637 bn37' onClick={handleButtonClicknoticiaA2}>Leer más...</button>
                                </div>
                            </div>
                        </article>
                        <article className="newsItem">
                            <div className="newsImageContainer">
                                <img
                                    src={nA3}
                                    alt="Imagen de la noticia"
                                    className="newsImage"
                                />
                            </div>
                            <div className="newsTextContainer">
                                <h3 className='tituloNoticia'>Proyecto Pronaii-Conahcyt con Gira</h3>
                                <p className='parrafoF'>Fecha: 06/05/2024</p>

                                <p className='parrafosNA'>Proyecto Pronaii-Conahcyt en el que participa GIRA junto con Uam
                                    azcapotzalco , Instituto de Investigaciones en Ecosistemas y Sustentabilidad UNAM ,
                                    Nodess Pátzcuaro - Zirahuén. , Panorama Ambiental A.C. TECNM Campus Pátzcuaro ,
                                    Grupo de Innovación Ecotecnológica y Bioenergía y comunidades de la región Lago de
                                    Patzcuaro y Meseta Purépecha </p>
                                <div className='buttonContainer'>
                                    <button className='bn3637 bn37' onClick={handleButtonClicknoticiaA3}>Leer más...</button>
                                </div>
                            </div>
                        </article>
                    </Slider>
                </div>

                <div className="bannerContainer">
                    <h1 className="tituloSeccion colortxt2">Eventos</h1>
                    <Banner />
                </div>
            </section>

        </div>

    );
}

//Boton para el direccionar a la página nosotros
const lineasEstrategicas = () => {
    window.location.href = '../LineasEstrategicas';

}
const alianzasPage = () => {
    window.location.href = '../Alianzas';

}
const cooperativasPage = () => {
    window.location.href = '../Osses';

};
const handleButtonClicknosotros = () => {
    window.location.href = '../Nosotros';
};

//Te diregin a las lineaas estrategicas
const handleButtonClickInvestigación = () => {
    window.location.href = '/LineasEstrategicas/Investigacion';
};

const handleButtonClickEmprendimiento = () => {
    window.location.href = '/LineasEstrategicas/EmprendimientosColect';
};

const handleButtonClickFortalecimiento = () => {
    window.location.href = '/LineasEstrategicas/EstrategiaFortalecimiento';
};

const handleButtonClickDifusion = () => {
    window.location.href = '/LineasEstrategicas/DivulgacionDifusion';
};

const handleButtonClickCultura = () => {
    window.location.href = '/LineasEstrategicas/CulturaPaz';
    
};

//Te dirigen a las alianzas
const handleButtonClickPie = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = '';
};

const handleButtonClickMigra = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://migrante.michoacan.gob.mx/';
};
const handleButtonClickAgric = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://sader.michoacan.gob.mx/';
};
const handleButtonClickEmp = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = '';
};
const handleButtonClickBie = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://bienestar.michoacan.gob.mx/';
};
const handleButtonClickCRS = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.crsespanol.org/donde-servimos/america-latina-y-el-caribe/mexico/';
};
const handleButtonClickPat = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://patzcuaro.gob.mx/';
};
const handleButtonClickTzi = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://tzintzuntzan.gob.mx/';
};
const handleButtonClickEro = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://erongaricuaro.gob.mx/';
};
const handleButtonClickAri = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.ario.gob.mx/';
};

//Te dirgigen a las cooperativas
const handleButtonClickmora = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/permalink.php?story_fbid=pfbid0QcUAxiSr7hZWGX6U4ozFxYSkYAZmYnrtyXqUABk6Kb7aoEaZAyi7tTvpceYPSTg6l&id=126850892303560';
};
const handleButtonClickxara = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100082983439845';
};
const handleButtonClicklaure = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/tsitiki.laure';
};
const handleButtonClickkat = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100071118049352';
};
const handleButtonClickjak = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100090766324384';
};
const handleButtonClickoro = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100077987243939';
};

//Noticias 

const handleButtonClicknoticiaultima = () => {
    window.location.href = 'https://www.facebook.com/share/p/R87DX6cqPRDuwL5G/?mibextid=oFDknk';
};  
const handleButtonClicknoticiaA2 = () => {
    window.location.href = 'https://www.facebook.com/share/p/DoTjNeEftdokQFCj/?mibextid=oFDknk';
};
const handleButtonClicknoticiaA3 = () => {
    window.location.href = 'https://www.facebook.com/share/p/2jZSqgpE8UFKfXCi/?mibextid=oFDknk';
};

export default Inicio