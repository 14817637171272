import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/generalStyles.css";
import chris from '../../Imagenes/Nosotros/Integrantes/christian.jpg'

function Member4Info() {
    return (
        /**Seccion  */
        <div className='container'>
            <h2 className="h1 tituloCentrado">Sobre mi</h2>

            <section className='section'>
                <div className='imageContainer'>

                    <img src={chris} alt="Imagen de la noticia" className='newsImage' />
                    <p className='justifiedText pStyle'>Mtro. Christian Omar Pérez Díaz</p>
                    <div>
                        <h3 className="h1 tituloCentrado ">Formación Académica</h3>

                        <p className='justifiedText pStyle'>El docente es licenciado en Mercadotecnia y maestro 
                        en Educación y Docencia por la Universidad TecMilenio Campus Morelia, que forma parte del 
                        Sistema Tecnológico de Monterrey.</p>
                    </div>
                </div>
                <div className='textContainer' >
                    <h2 className="h1 tituloCentrado ">Logros</h2>
                    <p className='justifiedText pStyle'>Cuenta con certificación CONOCER para la Formulación del diseño 
                    de proyectos de inversión rural ECO020, así como un Certificado CDC TecMilenio-ITESM en Marketing 
                    Digital. Es colaborador del cuerpo académico en formación ITSPA-CA-2 Gestión de Sistemas de 
                    Innovación y Desarrollo Territorial. Además, forma parte de la Red de investigación "Vinculación 
                    Social e Intervención en la Comunidad" del Programa Delfín. También es miembro del equipo operativo
                     del NODESS Pátzcuaro-Zirahuén y asesor del Centro de Incubación e Innovación Empresarial del ITSPA 
                     (CIIE-ITSPA).
                    </p>
                    <h2 className="h1 tituloCentrado ">Experiencias</h2>

                    <p className='justifiedText pStyle'>El docente es coordinador y colaborador en diversos proyectos 
                    institucionales de índole económico, mercadológico, cultura de la paz y sostenibilidad. Imparte 
                    asignaturas en las áreas económico-administrativas, investigación científica, ciencia y tecnología 
                    en la currícula de la Ingeniería en Administración y Gestión Empresarial.
                    </p>
                    <div>
                        <h2 className="h1 tituloCentrado ">Contacto</h2>
                        <p className='justifiedText pStyle'>cperez@itspa.edu.mx</p>
                    </div>
                </div>
            </section>
        </div>

    )
}



export default Member4Info;