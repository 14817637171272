import React from 'react'
import "../styles/BannerStyle"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../src/styles/BannerStyle";
import evento1 from '../Imagenes/noticias/2404_Taller.jpg'
import evento2 from '../Imagenes/noticias/2304_Convocatoria.jpg'
import evento3 from '../Imagenes/noticias/Poster Diplomado (2).jpg'

function Banner() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Habilita la reproducción automática
        autoplaySpeed: 3000, // Establece la velocidad de cambio en milisegundos (ejemplo: 3000 ms = 3 segundos)
    };
    return (
        <aside className="responsive-banner">
            <Slider {...settings}>
                <article>
                    <h2>Curso Taller de Educación Financiera Rural y Oportunidades de Desarrollo</h2>

                    <img src={evento1}/>
                    <div className="container-envelope">
                        <p>Participar en el curso taller “Educación Financiera Rural”</p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaTaller2404}>Conocer más...</button>
                        </div>
                    </div>
                </article>

                <article>
                    <h2>Convocatoria para la incubación y creación de empresas de Economía Social Solidaria</h2>
                    <img src={evento2}/>
                    <div className="container-envelope">
                        <p>Convocatoria para la incubación y creación de empresas de Economía Social Solidaria</p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaConvocatoria2304}>Conocer más...</button>
                        </div>
                    </div>
                </article>
                <article>
                    <h2>DIPLOMADO EN BIOREMEDIACION AMBIENTAL Y MICROBIOLOGIA APLICADA EN SUELO Y EN PRODUCCION AGROPECUARIA</h2>
                    <img src={evento3}/>
                    <div className="container-envelope">
                        <p>📢 ¡El Instituto Tecnológico Superior de Pátzcuaro invita a participar en el IV Diplomado en Agroecología! 🌿</p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaDiplomadoBio_Micro}>Conocer más...</button>
                        </div>
                    </div>
                </article>

            </Slider>


        </aside>

    );
}

const handleButtonClicknoticiaTaller2404 = () => {
    window.location.href = 'https://www.facebook.com/share/p/M9efHKGXYHJWmfmC/?mibextid=oFDknk';
};
const handleButtonClicknoticiaConvocatoria2304 = () => {
    window.location.href = 'https://www.facebook.com/share/p/WRTB7iBnKHnMUXak/?mibextid=oFDknk';
};
const handleButtonClicknoticiaDiplomadoBio_Micro = () => {
    window.location.href = 'https://www.facebook.com/share/p/FL5EeT6T5HT4Ny3f/?mibextid=oFDknk';
};
export default Banner;