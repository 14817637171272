import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/generalStyles.css";
import sergio from '../../Imagenes/Nosotros/Integrantes/sergio.jpg'

function Member3Info() {
    return (
        /**Seccion  */
        <div className='container'>
            <h2 className="h1 tituloCentrado">Sobre mi</h2>

            <section className='section'>
                <div className='imageContainer'>

                    <img src={sergio} alt="Imagen de la noticia" className='newsImage' />
                    <p className='justifiedText pStyle'>Mtro. Sergio Govea Valdez</p>
                    <div>
                        <h3 className="h1 tituloCentrado ">Formación Académica</h3>

                        <p className='justifiedText pStyle'>El docente investigador es Ingeniero Industrial 
                        con una Maestría en Calidad Total y Competitividad.</p>
                    </div>
                </div>
                <div className='textContainer' >
                    <h2 className="h1 tituloCentrado ">Logros</h2>
                    <p className='justifiedText pStyle'>Cuenta con la certificación de CONOCER como Evaluador de la 
                    competencia de candidatos con base en estándares de competencia EC0076. Tiene una especialidad 
                    en diseño de cursos formativos en habilidades rentables basados en tendencias, estándares y 
                    referencias internacionales. Además, es auditor líder y auditor interno en sistemas de gestión 
                    integral bajo las normas ISO 9000, certificado por IRCA-QSM. También está certificado en 
                    competencias, destrezas y técnicas de Programación Neurolingüística (PNL). Es miembro de 
                    la Red de investigación "vinculación social e intervención en la comunidad" del Programa 
                    Delfín y forma parte del equipo operativo del NODESS Pátzcuaro Zirahuén con registro 
                    SINCA_PNSS_20_00146_INAES. Asimismo, ha publicado diversos artículos de divulgación.
                    </p>
                    <h2 className="h1 tituloCentrado ">Experiencias</h2>

                    <p className='justifiedText pStyle'>Como docente, imparte asignaturas relacionadas con 
                    consultoría, administración de la calidad, plan de negocios, desarrollo de emprendedores,
                     innovación tecnológica e investigación de operaciones.
                    </p>


                    <div>
                        <h2 className="h1 tituloCentrado ">Contacto</h2>
                        <p className='justifiedText pStyle'>sgovea@itspa.edu.mx</p>
                    </div>
                </div>
            </section>
        </div>

    )
}



export default Member3Info;