import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/generalStyles.css";
import "../../Screens/inicio/inicio.css";
import "../../components/individualNewStyles.css"

function Noticias() {
    return (
        <div className='container color5'>
            <h1 className="tituloPagina tituloCentrado">Título de la Noticia</h1>

            <section class="noticia-detallada">
                <h1 className='tituloNoticia'>Subtitulo de la Noticia</h1>
                <p class="parrafoF">Fecha: 9 de marzo de 2024</p>

                <img src="Reunion2.1.jpg" alt="Imagen de la noticia" />
                <div class="contenido">
                    <p className='parrafosNA'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla condimentum finibus massa, eget cursus odio feugiat nec. Sed nec sapien eu sem egestas lacinia vel vitae justo. Fusce et hendrerit nunc.</p>
                    <p className='parrafosNA'>Integer vel fermentum velit, ac fermentum ipsum. Suspendisse potenti. Nunc tincidunt, purus non scelerisque scelerisque, orci nisl efficitur lectus, nec hendrerit purus massa a odio.</p>
                    <img src="Reunion2.1.jpg" alt="Otra imagen de la noticia" />
                    <p className='parrafosNA'>Proin euismod urna vitae lacus efficitur, non cursus lacus sagittis. Vivamus a ligula at justo sollicitudin volutpat. Fusce bibendum consectetur ullamcorper.</p>
                </div>

                <div className="creditos-autor">
                    <p className="autor">Escrito por: Nombre del Autor</p>
                    <p className="fecha-publicacion">Fecha de Publicación: 10 de marzo de 2024</p>
                    <p className="derechos">Todos los derechos reservados &copy; 2024</p>
                </div>
                
            </section>


        </div>
    );
}

export default Noticias;