import React from 'react'
import "../../../src/styles/generalStyles.css";
import "../../components/cardStyles.css"
import "../../styles/btnMenu.css"
import congreso from '../../Imagenes/LineasEstrategias/Investigacion/1erCONGRESO.jpeg'
import constancia from '../../Imagenes/LineasEstrategias/Investigacion/participacion_Evento_Ambiental.jpg'
import tzurumutaroGanadera from '../../Imagenes/LineasEstrategias/Investigacion/tzurumutaro_ganaderia.jpg'
import PRONACE from '../../Imagenes/LineasEstrategias/Investigacion/PRONACE320510.jpg'


function Investigacion() {
    return (
        <div className='container'>



            <section className='section2' id='investigacion'>
                <h1 className='tituloSeccion separador'>Investigación</h1>
                <p className='parrafosNA'>
                    NODESS cuenta con acciones para generar conocimiento a base de estudios,
                    recopilación, interpretación y aplicación de soluciones para  alguna problematica.
                    Se utilizan métodos formales de la investigación cientifica,  acorde al contexto social.
                    Estos serían los resultados
                </p>
            </section>

            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>1er CONGRESO INTERNACIONAL DE EDUCACIÓN PARA EL
                        DESARROLLO: “Innovación social, Inclusión y sostenibilidad en los
                        territorios”</h1>
                </div>

                <p className='parrafosNA'>
                    Se llevó a cabo la organización de esta iniciativa de la unidad académica de la Ingeniería
                    en administración del Instituto Tecnológico Superior de Pátzcuaro en colaboración del
                    Nodess Pátzcuaro -Zirahuén.
                    <br />
                    Los trabajos se llevaron a cabo los días 1, 2 y 3 de junio de 2023, en las instalaciones del
                    Instituto Tecnológico superior de Pátzcuaro.
                    <br />
                    Indicadores de alcance: Se presentaron 44 ponencias de investigadores de alto nivel de
                    15 instituciones de nivel superior del país y del extranjero, se realizaron 3 talleres y una
                    exposición de proyectos sustentables. Tuvimos un alcance aproximado de llegar a más de
                    300 personas.


                </p>
                <div className='contenedor-padre'>
                    <div >
                        <div className='newsImageContainer'>
                            <img src={congreso} alt="Imagen de la noticia" className='newsImage' />
                        </div>
                    </div>
                    <div >
                        <p className='parrafosNA'>

                            Se contó con la presencia de Importantes personalidades como ponentes, entre ellos el
                            Mtro. Juan Humberto Cerdio Vázquez Coordinador General de Fomento y Desarrollo
                            Empresarial
                        </p>
                    </div>
                </div>

            </section>

            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>PARTICIPACIÓN EN EVENTO DE EDUCACION AMBIENTAL COMO
                        CONFERENCISTA</h1>
                </div>
                <div className='contenedor-padre'>
                    <div style={{ alignContent: 'center' }} >
                        <div className='newsImageContainer' >
                            <img src={constancia} alt="Imagen de la noticia" className='newsImage' />
                        </div>
                    </div>
                    <div >
                        <p className='parrafosNA'>

                            Se impartió una conferencia a partir de las experiencias en educación ambiental en el
                            marco del Diplomado para la formación de Promotores Ambientales convocado y
                            coordinado por la UMSNH y el COECO.
                        </p>
                    </div>
                </div>

            </section >

            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>AVANCES DEL PROYECTO DE GANADERÍA REGENERATIVA EN
                        TZURUMÚTARO</h1>
                </div>

                <p className='parrafosNA'>
                    Como parte de la valoración de las parcelas participantes se tomaron muestras de biomasa
                    y de suelo en las parcelas. Se ha iniciado el análisis de parámetros fisicoquímicos en
                    laboratorio, sin embargo, no todos los análisis planteados se llevaron a cabo debido a
                    debido a que los equipos multiparamétricos se encuentras descalibrados y deben de
                    enviarse a mantenimiento, además de la necesidad de adquirir solución calibradora nueva.
                    <br />
                    Como parte del reforzamiento del taller de microorganismos acido lácticos eficientes, los
                    productores visitaron el laboratorio para observar al microscopio las diversas formas de vida
                    que se desarrollaron en el fermento. Se repitió la capacitación para reforzar la producción
                    de los microorganismos involucrándose algunos estudiantes.


                </p>
                <div className='contenedor-padre'>
                    <div style={{ alignContent: 'center' }} >
                        <div className='newsImageContainer'>
                            <img src={tzurumutaroGanadera} alt="Imagen de la noticia" className='newsImage' />
                        </div>
                    </div>
                    <div >
                        <p className='parrafosNA'>

                            Se hizo una evaluación visual de la vegetación recorriendo los potreros en transectos y las
                            plantas más abundantes se muestran en las imágenes de esta memoria fotográfica.

                        </p>
                    </div>
                </div>



            </section>

            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>ELABORACIÓN DE MECANISMO FINANCIERO PARA LA ADQUISICIÓN
                        DE ECOTECNOLOGÍAS ENERGÉTICAS EN COMUNIDADES RURALES
                        DEL PRONACE 320510</h1>
                </div>

                <div className='contenedor-padre'>
                    <div>
                        <div className='newsImageContainer'>
                            <img src={PRONACE} alt="Imagen de la noticia" className='newsImage' />
                        </div>
                    </div>
                    <div>
                        <p className='parrafosNA'>
                            Como parte de la colaboración en el proyecto PRONACE 320510 “Desarrollo de capacidades
                            técnicas, sociales y económicas para la implementación de ecotecnologías energéticas
                            sustentables en comunidades rurales con enfoque participativo”, se colabora en la
                            elaboración de un mecanismo financiero para la adquisición de ecotecnologías
                            energéticas en comunidades rurales en la región. A continuación, se agrega impresión de
                            pantalla del instrumento financiero.
                        </p>
                    </div>
                </div>
            </section>


           
            
        </div >
    );
}



export default Investigacion;