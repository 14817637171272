import React from 'react'
import "../../../src/styles/generalStyles.css";
import "../inicio/inicio.css";
import "../../components/cardStyles.css"

import jak from '../../Imagenes/cooperativas/jak_quechas_urichas.jpg'
import laure from '../../Imagenes/cooperativas/laure.jpg'
import kat from '../../Imagenes/cooperativas/Katsukua-uri.jpg'
import oro from '../../Imagenes/cooperativas/bordadosOro.jpg'
import xara from '../../Imagenes/cooperativas/Xaratanga-curicuaueri.jpg'
import mora from '../../Imagenes/cooperativas/Mora.jpg'

function Osses() {
    return (
        <div className="container">
            <h1 className="tituloPagina tituloCentrado">Osses</h1>
            <section className="section2 ">
                <p className='parrafosNA'>Las OSSES, siglas que significan "Organismos de la Sociedad Civil 
                de Interés Público", son instituciones no gubernamentales que tienen un interés en mejorar 
                y contribuir al desarrollo social y comunitario. Estas organizaciones suelen estar compuestas 
                por ciudadanos que comparten un objetivo común en áreas como la educación, la salud, el medio 
                ambiente, el desarrollo económico, entre otros.
                </p>
                <div className='contenedor-padre'>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={mora} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Esperanza Artesanal "La Mora"</h3>
                            <p className='parrafosNA'>La cooperativa se dedica al diseño, elaboración y 
                            comercialización de artesanías de huinumo y textiles, de la comunidad de Zarzamora,
                             Michoacán.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37'  onClick={handleButtonClickmora}>Conocer más</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={xara} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Xaratanga Curicuaueri</h3>
                            <p className='parrafosNA'>La coopertiva Xaratanga Curicuaeri son un grupo emprendedores 
                            que se dedica a la elaboración artesanías, de la comunidad de Erongarícuaro, Michoacán.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickxara}>Conocer más</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={laure} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Tsitsiki Laure</h3>
                            <p className='parrafosNA'>Somos un grupo de mujeres de la comunidad de San Miguel 
                            Nocutzepo que elaboramos a mano distintas prendas para la vestimenta y el hogar.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicklaure}>Conocer más</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={jak} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Jak Quechas Urichas</h3>
                            <p className='parrafosNA'>Jak quechas Urichas somos un grupo de artesanas de San Francisco 
                            Uricho dedicadas a la creación de prendas artesanales hechas a mano, buscamos ser tu mejor
                             opción.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickjak}>Conocer más</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={kat} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Katsujua Uri</h3>
                            <p className='parrafosNA'>Somos artesanos dedicados a elaborar sombreros artesanales en la comunidad de Jarácuaro, 
                            Michoacán.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickkat}>Conocer más</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={oro} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Bordados de Oro</h3>
                            <p className='parrafosNA'>Somos artesanos dedicads a la cultura, tradiciones y bordados 
                            artesanales de la comunidad de Tzintzuntzan, Michoacán.</p>
                        </div>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickoro}>Conocer más...</button>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

const handleButtonClickmora = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/permalink.php?story_fbid=pfbid0QcUAxiSr7hZWGX6U4ozFxYSkYAZmYnrtyXqUABk6Kb7aoEaZAyi7tTvpceYPSTg6l&id=126850892303560';
};
const handleButtonClickxara = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100082983439845';
};
const handleButtonClicklaure = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/tsitiki.laure';
};
const handleButtonClickkat = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100071118049352';
};
const handleButtonClickjak = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100090766324384';
};
const handleButtonClickoro = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100077987243939';
};


export default Osses;