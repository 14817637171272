import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/generalStyles.css";
import lety from '../../Imagenes/Nosotros/Integrantes/leticia.jpg'


function Member1Info() {
    return (
        /**Seccion  */
        <div className='container'>
            <h2 className="h1 tituloCentrado">Sobre mi</h2>

            <section className='section'>
                <div className='imageContainer'>

                    <img src={lety} alt="Imagen de la noticia" className='newsImage' />
                    <p className='justifiedText pStyle'>MC. Leticia Oseguera Figueroa</p>
                    <div>
                        <h3 className="h1 tituloCentrado ">Formación Académica</h3>

                        <p className='justifiedText pStyle'>La docente investigadora es licenciada en Biología 
                        por la Facultad de Biología de la UMSNH. Obtuvo una Maestría en Ciencias en Limnología 
                        y Acuicultura por el INIRENA-UMSNH.</p>
                    </div>
                </div>
                <div className='textContainer' >
                    <h2 className="h1 tituloCentrado ">Logros</h2>
                    <p className='justifiedText pStyle'>Cuenta con una certificación vigente en Proyectos de 
                    Transición Agroecológica. Además, es miembro de la Red Mexicana de cuencas, así como impulsora 
                    y miembro del Comité Regional Ambientalista A.C. de Tonalá, Chiapas, y de Operación Mascota A.C. 
                    en Pátzcuaro.
                    </p>
                    <h2 className="h1 tituloCentrado ">Experiencias</h2>

                    <p className='justifiedText pStyle'>La docente tuvo experiencia como profesora en 
                    la carrera de Acuicultura del CONALEP 170 desde 1994 hasta 2000, y de 2000 a 2004 
                    enseñó en la carrera de laboratorista químico del CBTIS 170 en Tonalá. En la actualidad, 
                    es docente investigadora en la carrera de Ingeniería en Desarrollo Comunitario en el 
                    Instituto Tecnológico Superior de Pátzcuaro. También se desempeñó como asesora en el
                     Centro de Innovación e Incubación de Empresas y fue Directora de Ecología del Ayuntamiento 
                     Municipal de noviembre de 2016 a marzo de 2018. Fue Vocal Académica de la Comisión de Cuenca 
                     del Lago de Pátzcuaro de 2013 a 2021 y ha asistido como ponente en diversos congresos. 
                     Además, imparte asignaturas en biología, zoología, fisiología vegetal, manejo de agua,
                      formulación de proyectos y planificación regional.
                    </p>


                    <div>
                        <h2 className="h1 tituloCentrado ">Contacto</h2>
                        <p className='justifiedText pStyle'>loseguera@itspa.edu.mx</p>
                    </div>
                </div>
            </section>
        </div>

    )
}



export default Member1Info;