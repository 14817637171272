import leticia from '../Integrantes/leticia.jpg'
import christian from '../Integrantes/christian.jpg'
import miriam from '../Integrantes/miriam.jpg'
import sergio from '../Integrantes/sergio.jpg'
import soraida from '../Integrantes/soraida.jpg'
import DraGTC from '../Integrantes/DraGTC.png'

export default {
    "leticia": leticia,
    "christian": christian,
    "miriam":miriam,
    "sergio": sergio,
    "soraida": soraida,
    "DraGTC": DraGTC,
}