import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/cardsStylesEqOperativo.css";
import "../../../src/styles/generalStyles.css";
import organo from '../../Imagenes/Nosotros/OrganoRector.jpg'
//import equipo from "../../Imagenes/Nosotros/Integrantes"
import EquipoOperativo from "../../Imagenes/Nosotros/Integrantes/EquipoOperativo";


function Nosotros() {
    return (
        <div className="container">
            <h1 className="tituloPagina ">Nosotros</h1>

            <section className="section ">
                <div className="tituloCentrado">
                    <h2 className="tituloSeccion">¿Qué es NODESS?</h2>
                    <div className='textContainer'>
                        <p className='parrafosNA'>Los nodos de impulso a la economía social
                            y solidaria (NODESS), son alianzas locales integradas de manera
                            voluntaria, con al menos tres actores institucionales interesados
                            en fomentar y desarrollar la economía social y solidaria en el
                            territorio.
                        </p>
                    </div>
                </div>
            </section>

            <section className="section ">
                <div className="tituloCentrado">
                    <h2 className="tituloSeccion">Misión</h2>
                    <div className='textContainer'>
                        <p className='parrafosNA'>Generar estrategias y acciones que fomenten e 
                        impulsen el desarrollo de prácticas y ejercicios desde la economía social 
                        y solidaria, que coadyuven en la reducción de las desigualdades sociales 
                        y la construcción de paz en la región Pátzcuaro-Zirahuén.

                        </p>
                    </div>
                </div>
                <div className="tituloCentrado">
                    <h2 className="tituloSeccion">Visión</h2>
                    <div className='textContainer'>
                        <p className='parrafosNA'>Tiene como objetivo impulsar un desarrollo más 
                        justo y equitativo en la región, creando oportunidades y fortaleciendo los 
                        lazos comunitarios.
                        </p>
                    </div>
                </div>
            </section>

            <section className="section ">
                <div className="tituloCentrado">
                    <h2 className="tituloSeccion">Historia</h2>
                    <div className='textContainer'>
                        <p className='parrafosNA'>En 2020 se integró el NODESS Pátzcuaro-Zirahuén
                            conformado por TecNM campus Pátzcuaro, los Ayuntamientos de Pátzcuaro y Quiroga,
                            las cooperativas "Cam Mujeres" y "Chapitiro" del municipio de Pátzcuaro, Mich.;
                            el Grupo Interdisciplinario de Tecnología Rural Apropiada A.C. (GIRA); y como aliados
                            el Ayunta- miento de Tzintzuntzan y el Centro de Regional de Educación para el
                            Desarrollo Sustentable (CREDES-SEMARNAT).
                            <br />
                            <br/>
                            Las líneas bajo las cuales se iniciaron los trabajos fueron:
                            <br />

                            1. Investigación,
                            <br />
                            2. Divulgación, difusión y publicación,
                            <br />
                            3. Fortlecimiento y acompañamiento,
                            <br />
                            4. Emprendimiento asociativo,
                            <br />
                            5. Cultura de paz, y
                            <br />
                            6. Gestión integrada de cuencas, identidad, cultura y soberanía alimentaria.
                            <br />
                            <br/>
                            El NODESS Pátzcuaro-Zirahuén tiene el firme compromiso de continuar trabajando para contribuir en la creación, organización y fortalecimiento de las empresas del sector de la economía social solidaria, desde el trabajo colaborativo generado entre las instituciones educativas, los gobiernos locales, las organizaciones de la sociedad civil, comunidades y ciudadanía.
                            <br/>
                            

                        </p>
                    </div>
                </div>
            </section>

            <section className="section ">
                <div className="tituloCentrado">
                    <h2 className="tituloSeccion colortxt2">Organo Rector</h2>
                    <img src={organo} alt="Imagen" className='newsImageContainer' />
                </div>
            </section>

            <section className="section2 color3">
                <div className="tituloCentrado">
                    <h2 className="tituloPagina colorSeccionLetra">Equipo Operativo</h2>
                </div>

                <div className="sectionEO">


                    <button className='bnCard bnCardColor cardEO ' onClick={handleButtonClickLety}>
                        <img src={EquipoOperativo.leticia} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">MC. Leticia Oseguera Figueroa</h2>
                            <p class="card-description">Licenciada en Biología por la Facultad de Biología de la UMSNH.</p>
                        </div>
                    </button>

                    <button className='bnCard bnCardColor cardEO ' onClick={handleButtonClickSoraida}>
                        <img src={EquipoOperativo.soraida} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">Dra. Soraida Quezada Ascencio</h2>
                            <p class="card-description">Docente Investigadora del Instituto Tecnológico Superior de Pátzcuaro</p>
                        </div>
                    </button>
                    <button className='bnCard bnCardColor cardEO'  onClick={handleButtonClickSergio} >
                        <img src={EquipoOperativo.sergio} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">Mtro. Sergio Govea Valdez</h2>
                            <p class="card-description">Docente e Investigador del Instituto Tecnologio Superior de Pátzcuaro</p>
                        </div>
                    </button>
                    <button className='bnCard bnCardColor cardEO'  onClick={handleButtonClickchristian}>
                        <img src={EquipoOperativo.christian} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">Mtro. Christian Omar Pérez Díaz</h2>
                            <p class="card-description">Docente e Investigador del Instituto Tecnologio Superior de Pátzcuaro</p>
                        </div>
                    </button>
                    <button className='bnCard bnCardColor cardEO '  onClick={handleButtonClickMiriam}>
                        <img src={EquipoOperativo.miriam} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">Dra. Miriam E. Aguirre Arias</h2>
                            <p class="card-description">Docente Investigadora del Tecnológico Superior de Pátzcuaro</p>
                        </div>
                    </button>
                    <button className='bnCard bnCardColor cardEO '  onClick={handleButtonClickGuadalupe}>
                        <img src={EquipoOperativo.DraGTC} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">Dre. Ma. Guadalupe Torres Chávez</h2>
                            <p class="card-description">Docente e Investigador del Instituto Tecnologio Superior de Pátzcuaro</p>
                        </div>
                    </button>
                </div>

            </section>


        </div>
    );

}
const handleButtonClickLety = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member1Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
const handleButtonClickGuadalupe = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member2Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
const handleButtonClickSergio = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member3Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
const handleButtonClickchristian = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member4Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
const handleButtonClickSoraida = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member5Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
const handleButtonClickMiriam = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member6Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
export default Nosotros;