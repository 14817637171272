import React from 'react'
import "../../../src/styles/generalStyles.css";
import "../../components/cardStyles.css"
import "../../styles/btnMenu.css"
import jak from '../../Imagenes/cooperativas/jak_quechas_urichas.jpg'
import laure from '../../Imagenes/cooperativas/laure.jpg'
import kat from '../../Imagenes/cooperativas/Katsukua-uri.jpg'
import oro from '../../Imagenes/cooperativas/bordadosOro.jpg'
import xara from '../../Imagenes/cooperativas/Xaratanga-curicuaueri.jpg'
import organizacion_cooperativa from '../../Imagenes/LineasEstrategias/Emprendimiento/Organización_Cooperativa.jpg'


function EmprendimientosColect() {
    return (
        <div className='container'>

<section className='section2' id='emprendimientos'>
                <h1 className='tituloSeccion separador'>Emprendimientos Colectivos</h1>
                <p className='parrafosNA'>
                    NODESS te brinda la ayuda a que formes tu Cooperativa, contamos con
                    cooperativas en formación,  y contamos con programas
                    para la creación de incubación de empresas de ESS.
                </p>
            </section>

            <section className='section2 color1'>

                <div>
                    <h1 className=' separador h2 tituloSeccion'>Cooperativas</h1>
                </div>

                <div className='contenedor-padre'>

                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={oro} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Esperanza Artesanal "La mora"</h3>
                            <p className='parrafosNA'>Texto descriptivo sobre la tarjeta 2. Puedes agregar más detalles aquí.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickmora}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={jak} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Jak Quechas Urichas</h3>
                            <p className='parrafosNA'>Texto descriptivo sobre la tarjeta 2. Puedes agregar más detalles aquí.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickjak}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9' >
                        <div className='imageCardContainer'>
                            <img src={xara} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Xaratanga Curicuaueri</h3>
                            <p className='parrafosNA'>Texto descriptivo sobre la tarjeta 2. Puedes agregar más detalles aquí.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickxara}>Ver más</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={oro} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Bordados de Oro</h3>
                            <p className='parrafosNA'>Texto descriptivo sobre la tarjeta 2. Puedes agregar más detalles aquí.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickoro}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={laure} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Tsitsiki Laure</h3>
                            <p className='parrafosNA'>Texto descriptivo sobre la tarjeta 2. Puedes agregar más detalles aquí.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicklaure}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={kat} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Cooperativa Katsujua Uri</h3>
                            <p className='parrafosNA'>Texto descriptivo sobre la tarjeta 2. Puedes agregar más detalles aquí.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickkat}>Ver más</button>
                        </div>
                    </div>

                </div>
            </section>

            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>4.1.ORGANIZACIÓN DE LA COOPERATIVA DE PRODUCTORES AGROPECUARIOS DE TZURUMUTARO</h1>
                </div>

                <p className='parrafosNA'>
                    Se colabora en la organización de la cooperativa manteniendo contacto con personal de Espacio
                    Emprendedor y con los ganaderos para la conformación de la cooperativa, con especial interés
                    en dar formalidad a la organización y que se pueda acceder a los beneficios que otorga el
                    trabajo formal y en grupo. Para tal efecto se ha insistido en reunir los documentos para
                    expediente y el apoyo por parte de Espacio Emprendedor en la Secretaría de economía del estado,
                    para que se de asesoría y se lleve a cabo la protocolización y registro de estatutos sin costo.
                </p>
                <div className='contenedor-padre'>
                    <div>
                        <div className='newsImageContainer'>
                            <img src={organizacion_cooperativa} alt="Imagen de la noticia" className='newsImage' />
                        </div>
                    </div>
                    <div>
                        <p className='parrafosNA'>
                            Se preparó una propuesta de acta de	asamblea	con	un planteamiento de objeto
                            social y se instó a la firma e integración de expediente, así como a la elección
                            de representantes.
                            <br />
                            El grupo se consolidó en 2023 con la protocolización de su acta constitutiva
                            ante notario, quedando en espera de la resolución del notario
                        </p>
                    </div>
                </div>


            </section>

        </div>
    );
}

const handleButtonClickmora = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/permalink.php?story_fbid=pfbid0QcUAxiSr7hZWGX6U4ozFxYSkYAZmYnrtyXqUABk6Kb7aoEaZAyi7tTvpceYPSTg6l&id=126850892303560';
};
const handleButtonClickxara = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100082983439845';
};
const handleButtonClicklaure = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/tsitiki.laure';
};
const handleButtonClickkat = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100071118049352';
};
const handleButtonClickjak = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100090766324384';
};
const handleButtonClickoro = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.facebook.com/profile.php?id=100077987243939';
};

export default EmprendimientosColect;