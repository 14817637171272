import React from 'react'
import "../../../src/styles/generalStyles.css";
import "../../styles/LineasEstrategicasStyle.css"
import "../../components/cardStyles.css"
import "../../styles/btnMenu.css"
import diseñoMOOC from '../../Imagenes/LineasEstrategias/Estrategia De Fortalecimiento/diseñoMOOC.jpg'
import taller from '../../Imagenes/LineasEstrategias/Estrategia De Fortalecimiento/TALLER_DE_EDUCACIÓN_AMBIENTAL.jpg'
import RedEstatalNodes from '../../Imagenes/LineasEstrategias/Estrategia De Fortalecimiento/nodes_morelia.jpg'
import integracon_reunion from '../../Imagenes/LineasEstrategias/Estrategia De Fortalecimiento/Integracion_reunion.jpg'
import colaboracionTecnicos from '../../Imagenes/LineasEstrategias/Estrategia De Fortalecimiento/ColaboracionTecnicos.jpg'
import seguimiento_1 from '../../Imagenes/LineasEstrategias/Estrategia De Fortalecimiento/SeguimientoMetodologíaGAAP_1.jpg'
import seguimiento_2 from '../../Imagenes/LineasEstrategias/Estrategia De Fortalecimiento/SeguimientoMetodologíaGAAP_2.jpg'


function EstrategiaFortalecimiento() {
    return (
        <div className='containerMain'>



            <section className='sectionLE' id='fortalecimiento'>
                <h1 className='tituloPaginaLE colortxt2 '>Estrategia De Fortalecimiento De Capacidades</h1>
                <p className='parrafosLE'>
                    Son acciones de diagnóstico y capacitación focalizada en las necesidades de la ESS y las
                    cooperativas,, que brinden las herramientas y los conocimientos necesarios para impuslsar
                    y mejorar las capacidades empresariales, tecnológicas y de innovación, tanto de empresas
                    sociales, organizaciones e iniciativas del sector en las diferentes etapas; además de apoyar
                    a la vivencia de los valores y principios de la ESS.
                </p>
            </section>

            <section className='sectionLE' >

                <h1 className='tituloSeccion '>Diseño De Un Curso Mooc En Economía Social Y Solidaria Para
                    La Plataforma Del TECNM. </h1>
                <p className='parrafosNA'>
                    Captación de participantes para el MOOC de Emprendimientos Colectivos a nivel nacional:
                    <br />
                    Inscripciones: Logro de la meta de captación de 1663 estudiantes para el MOOC de
                    Emprendimientos Colectivos, superando las expectativas iniciales.
                    <br />
                    Constancias emitidas: 863 Mantenimiento de una tasa de retención del 52% de los
                    estudiantes inscritos durante todo el curso.
                </p>

                <div className='newsImageContainer'>
                    <img src={diseñoMOOC} alt="Imagen de la noticia" className='newsImage' />
                </div>




            </section>

            <section className='sectionLE ' >
                <div>
                    <h1 className='tituloSeccion  '>Implementación Del Taller De Educación Ambiental Como
                        Parte De Las Estrategias En El Manejo Integral De Residuos
                        ARIOCIMAR, En El Municipio De Ario de Rosales, Michoacán. </h1>
                </div>

                <p className='parrafosNA'>
                    En este periodo se dio continuidad con el curso taller sobre formación y sensibilización de
                    educación ambiental para el manejo de RSU a jóvenes estudiantes del COBAEM Ario, por
                    ser considerados los agentes que tienen contacto directo con los residuos. Se agrega
                    imagen del cartel de publicidad del curso, impresión de pantalla de la carta descriptiva del
                    curso, fotografías de su implementación y la constancia de este.

                </p>

                <div className='newsImageContainer'>
                    <img src={taller} alt="Imagen de la noticia" className='newsImage' />
                </div>

            </section>

            <section className='sectionLE ' >
                    <h1 className='tituloSeccion '>Integración De La Red Estatal De NODESS Michoacán </h1>
                <div className='contenedor-padre'>
                    <div className='imageSubContainer'>

                        <p className='parrafosNA'>
                            Se llevó a cabo la primer reunión de trabajo en las instalaciones del tecnológico de
                            Morelia, con la participación de representantes de los nodess del estado.
                        </p>

                        <img src={RedEstatalNodes} alt="Icono 1" className='icon' />

                    </div>
                    <div className='imageSubContainer'>

                        <p className='parrafosNA'>
                            En el marco del 1er. Congreso Internacional de Educación para el Desarrollo, se llevó
                            a cabo la reunión de confirmación oficial de la red de NODESS del estado de Michoacán,
                            con la presencia de 11 NODESS y 3 prenodess , con la presencia del Mtro Juan Humberto
                            Cerdio Vázquez, Coordinador general de fomento empresarial de INAES, el CP. Luis David
                            Soto Quezaman subsecretario del Bienestar y CP. J.Jesús Vega Covarrubias Director
                            General del Instituto Tecnológico Superior de Pátzcuaro. Los presentes dialogaron en la
                            ruta para consolidar los esfuerzos de seguir impulsando la economía social solidaria en el
                            estado.
                        </p>
                        <img src={integracon_reunion} alt="Icono 1" className='icon' />

                    </div>
                </div>
            </section>

            <section className='sectionLE color5' >
                <div>
                    <h1 className='tituloDerecha separador h2'>COLABORACION CON TÉCNICOS DE AGROSANO PARA EL TALLER DE
                        INSEMINACIÓN ARTIFICIAL POR PARTE DE ESPECIALISTA DE CEFOGA </h1>
                </div>

                <p className='parrafosNA'>
                    El taller de capacitación se llevó a cabo en las instalaciones de la Casa Ejidal y en parcela de
                    uno de los productores en cuatro viernes de abril y mayo.
                </p>

                <div className='newsImageContainer'>
                    <img src={colaboracionTecnicos} alt="Imagen de la noticia" className='newsImage' />
                </div>

            </section>

            <section className='sectionLE color5' >
                <div>
                    <h1 className='tituloDerecha separador h2'>SEGUIMIENTO A METODOLOGÍA GAAP (GRUPOS DE
                        AUTOAHORRO Y PRÉSTAMOS) </h1>
                </div>
                <div className='contenedor-padre'>
                    <div className='imageSubContainer'>
                        <p className='parrafosNA'>
                            Se atienden tres grupos que han iniciado su conformación como grupo de auto ahorro
                            como parte de las actividades del NODESS. Uno de los grupos es en Casas Blancas, municipio de Salvador Escalante, otro se ubica en la cabecera municipal ciudad de Santa
                            Clara y el tercero en la comunidad de Ichupio en el municipio de Tzintzuntzan. Este se ha
                            vinculado al trabajo técnico del programa Pies Ágiles.
                            <br />
                            En estos grupos se han conformado cajas de auto ahorro. Los grupos de Santa Clara e
                            Ichupio, han sido invitados a integrar cooperativas legalmente constituidas. En el caso de
                            Casas Blancas, no ha habido tal constancia ene l seguimiento de las acciones a pesar de
                            que si han recibido capacitación en diversos temas. Los grupos de Santa Clara y Casas
                            Blancas están en su segundo ciclo semestral de ahorro en tanto que Ichupio ha establecido
                            el primero.
                        </p>
                        <img src={seguimiento_1} alt="Icono 1" className='icon' />

                    </div>
                    <div className='imageSubContainer'>
                        <p className='parrafosNA'>
                            Se llevaron a cabo algunas reuniones virtuales para conocer la plataforma Camino hacia
                            la paz que tiene el propósito de llevar en una app o plataforma virtual todos los datos de
                            las GAAP a nivel Latinoamérica.
                        </p>
                        <img src={seguimiento_2} alt="Icono 1" className='icon' />

                    </div>
                </div>
            </section>
        </div>
    );
}



export default EstrategiaFortalecimiento;