import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/generalStyles.css";
import "../../Screens/inicio/inicio.css";
import "../../components/newsListStyles.css"
import n1 from '../../Imagenes/noticias/n1.jpg'
import noticia1 from '../../Imagenes/noticias/noticia1.jpg'
import n2 from '../../Imagenes/noticias/n2.jpg'
import n3 from '../../Imagenes/noticias/n3.jpg'
import n4 from '../../Imagenes/noticias/n4.jpg'
import n5 from '../../Imagenes/noticias/n5.jpg'
import n6 from '../../Imagenes/noticias/n6.jpg'
import n7 from '../../Imagenes/noticias/n7.jpg'
import nA2 from '../../Imagenes/noticias/noticia_A2.jpg'
import nA3 from '../../Imagenes/noticias/nA3.jpg'
import nA4 from '../../Imagenes/noticias/nA4.jpg'
import convocatoria_2304 from '../../Imagenes/noticias/2304_Convocatoria.jpg'
import Taller_2404 from '../../Imagenes/noticias/2404_Taller.jpg'
import Iso_2504_1 from '../../Imagenes/noticias/2504_1.jpg'
import Congreso_2504_2 from '../../Imagenes/noticias/2504_2.jpg'
import Inaguración_2604 from '../../Imagenes/noticias/2604.jpg'


function NewsList() {
    return (
        <div className='container '>
            <h1 className="tituloPagina tituloCentrado">Noticias Nodess Pátzcuaro-Zirahuén</h1>
            <section className="container">

                <div className='noticias-container'>
                    <div className='news-content-img'>
                        <img src={n1} alt="Imagen de la noticia" className='newsImage' />
                    </div>

                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Visita de los alumnos del CECYTEM 25 Opopeo al Intituto Tecnologico Superior de Pátzcuaro</h2>

                        <p className='parrafosNA colortxt3'> Hoy recibimos en el Instituto Tecnológico Superior de Pátzcuaro
                            con alegría la visita de los alumnos del CECYTEM 25 Opopeo, en nuestro taller de EmprendeTEC
                            en Administración donde la Ingeniería de Administración está marcando la diferencia.</p>
                        <p className='parrafoF'>Fecha: 11/04/2024</p>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaultima}>Leer más...</button>
                        </div>
                    </div>
                </div>

                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={Inaguración_2604} alt="Imagen de la noticia" className='newsImage' />
                    </div>
                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Inauguración de InnovaTecNM 2024: Semillero de Innovación</h2>
                        <p className='parrafoF'>Fecha: 26/04/2024</p>
                        <p className='parrafosNA'> Acto protocolario de inauguración del InnovaTecNM 2024, cumbre nacional de desarrollo
                            tecnológico, semillero de proyectos e ideas innovadoras.</p>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaInaguracion_2604}> Ver más..-</button>
                        </div>
                    </div>
                </div>
                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={Congreso_2504_2} alt="Imagen de la noticia" className='newsImage' />
                    </div>
                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Recuerdos del Congreso en Pucon, Chile: Excelencia en Ing. en Administración</h2>
                        <p className='parrafoF'>Fecha: 25/04/2024</p>
                        <p className='parrafosNA'> Momentos memorables en congresos internacionales! 💼🌎 Recordando nuestra participación
                            en el congreso en Pucon, Chile 🇨🇱 donde la Ingeniería en Administración demostró su excelencia y promovió nuestra
                            institución con orgullo.</p>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaCongreso_2504}> Ver más..-</button>
                        </div>
                    </div>
                </div>
                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={Iso_2504_1} alt="Imagen de la noticia" className='newsImage' />
                    </div>

                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>TECNM Campus Pátzcuaro Certificado en Sistema de Gestión Integral ISO: Reconocimiento a la "Calidad".</h2>
                        <p className='parrafoF'>Fecha: 25/04/2024</p>
                        <p className='parrafosNA'> Durante la Reunión Nacional de Revisión por la Dirección del SGI, desarrollada en Ites Los Cabos.
                            El TECNM Campus Pátzcuaro recibió la Certificación en el Sistema de Gestión Integral, en la normas ISO-9001, ISO-14001,
                            ISO-45001 e ISO-50001 por el periodo 2024-2027.</p>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaIso2504}> Ver más..-</button>
                        </div>
                    </div>
                </div>
                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={Taller_2404} alt="Imagen de la noticia" className='newsImage' />
                    </div>

                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Curso Taller de Educación Financiera Rural y Oportunidades de Desarrollo</h2>
                        <p className='parrafoF'>Fecha: 24/04/2024</p>
                        <p className='parrafosNA'> El Tecnológico Superior de Pátzcuaro a través del nodess Pátzcuaro-Zirahuen y la
                            Secretaría de Agricultura y Desarrollo Rural. Invitan a la comunidad estudiantil 👩‍🎓👨‍🎓a participar en el
                            curso taller “Educación Financiera Rural” con el propósito de formar Promotores y promotoras que fomenten
                            la seguridad en los cultivos en beneficio del desarrollo local.</p>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaTaller2404}> Ver más..-</button>
                        </div>
                    </div>
                </div>
                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={convocatoria_2304} alt="Imagen de la noticia" className='newsImage' />
                    </div>

                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Convocatoria para la incubación y creación de empresas de Economía Social Solidaria</h2>
                        <p className='parrafoF'>Fecha: 23/04/2024</p>
                        <p className='parrafosNA'> El TECNM Campus Pátzcuaro a través del Centro de Incubación e Innovación Empresarial,
                            y en colaboración del Nodess Pátzcuaro - Zirahuén. te invita a participar en la 𝐂𝐎𝐍𝐕𝐎𝐂𝐀𝐓𝐎𝐑𝐈𝐀 para la 𝑰𝒏𝒄𝒖𝒃𝒂𝒄𝒊𝒐́𝒏
                            𝒚 𝑪𝒓𝒆𝒂𝒄𝒊𝒐́𝒏 𝒅𝒆 𝑬𝒎𝒑𝒓𝒆𝒔𝒂𝒔 𝒅𝒆 𝑬𝒄𝒐𝒏𝒐𝒎𝒊́𝒂 𝑺𝒐𝒄𝒊𝒂𝒍 𝑺𝒐𝒍𝒊𝒅𝒂𝒓𝒊𝒂.
                        </p>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaConvocatoria2304}> Ver más..-</button>
                        </div>
                    </div>
                </div>



                <div className='noticias-container'>
                    <div className='news-content-img'>
                        <img src={n1} alt="Imagen de la noticia" className='newsImage' />
                    </div>

                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Visita de los alumnos del CECYTEM 25 Opopeo al Intituto Tecnologico Superior de Pátzcuaro</h2>

                        <p className='parrafosNA colortxt3'> Hoy recibimos en el Instituto Tecnológico Superior de Pátzcuaro
                            con alegría la visita de los alumnos del CECYTEM 25 Opopeo, en nuestro taller de EmprendeTEC
                            en Administración donde la Ingeniería de Administración está marcando la diferencia.</p>
                        <p className='parrafoF'>Fecha: 11/04/2024</p>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaultima}>Leer más...</button>
                        </div>
                    </div>
                </div>

                <div className="noticias-container ">
                    <div className='news-content-img'>
                        <img src={nA2} alt="Imagen de la noticia" className='newsImage' />
                    </div>

                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Cosas que debes de saber con el eclipse</h2>

                        <p className='parrafosNA colortxt3'>
                            ¡Es hoy, es hoy! Antes de que llegue el momento más esperado, esto es lo que NO hay que hacer durante el eclipse
                        </p>
                        <p className='parrafoF'>Fecha: 8/04/2024</p>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaA2}>Leer más...</button>
                        </div>
                    </div>

                </div>

                <div className='noticias-container'>
                    <div className='news-content-img'>
                        <img src={nA3} alt="Imagen de la noticia" className='newsImage' />
                    </div>
                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Proyecto Pronaii-Conahcyt con Gira</h2>
                        <p className='parrafoF'>Fecha: 06/05/2024</p>

                        <p className='parrafosNA colortxt3'>Proyecto Pronaii-Conahcyt en el que participa GIRA junto con Uam
                            azcapotzalco , Instituto de Investigaciones en Ecosistemas y Sustentabilidad UNAM ,
                            Nodess Pátzcuaro - Zirahuén. , Panorama Ambiental A.C. TECNM Campus Pátzcuaro ,
                            Grupo de Innovación Ecotecnológica y Bioenergía y comunidades de la región Lago de
                            Patzcuaro y Meseta Purépecha </p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaA3}>Leer más...</button>
                        </div>
                    </div>

                </div>

                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={nA4} alt="Imagen de la noticia" className='newsImage' />
                    </div>
                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Participación de estudiantes en el Congreso Internacional Científico TecNM en la ciudad de Acapulco Guerrero</h2>
                        <p className='parrafoF'>Fecha: 04/04/2024</p>

                        <p className='parrafosNA colortxt3'>Recordando la participación de nuestros estudiantes de Ingeniería en Administración
                            del Instituto Tecnológico Superior de Pátzcuaro en el Segundo Congreso Internacional Científico TecNM en la
                            ciudad de Acapulco Guerrero 🌊 🏖️ ☀️ ! 🌟 Participando junto a estudiantes de más de 400 planteles
                            TecNM a nivel nacional, ¡y destacando brillantemente! 💡 Desarrollando competencias clave como trabajo
                            en equipo, liderazgo e innovación, ¡demostrando el talento y la dedicación de nuestra comunidad
                            estudiantil!</p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticiaA4}>Leer más...</button>
                        </div>
                    </div>
                </div>

                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={n7} alt="Imagen de la noticia" className='newsImage' />
                    </div>
                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Reunión con la Universiada Latina de America UNLA para trabajar en conjunto</h2>
                        <p className='parrafoF'>Fecha: 12/02/2024</p>

                        <p className='parrafosNA colortxt3'>¡Emocionante noticia! Parte del equipo operativo del Nodess Pátzcuaro-Zirahuén,
                            bajo la coordinación de la Dra. Miriam Aguirre Arias, se reunió en la Universiada Latina de America
                            UNLA para trabajar en la creación de la Red Nodess. Estamos comprometidos en generar beneficios
                            tangibles en la Economía Social Solidaria de nuestra región. ¡Juntos construimos un futuro más próspero
                            y sostenible! </p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticia7}>Leer más...</button>
                        </div>
                    </div>
                </div>

                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={n6} alt="Imagen de la noticia" className='newsImage' />
                    </div>
                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Te incribiste a un curso en linea con INAES</h2>
                        <p className='parrafoF'>Fecha: 12/02/2024</p>

                        <p className='parrafosNA colortxt3'>Si realializaste tu inscripción a un curso en la plataforma del
                            Sistema Nacional de Capacitación y Asistencia Técnica Especializada (SINCA) del Instituto
                            Nacional de la Economía Social INAES. Esta información es para ti</p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticia6}>Leer más...</button>
                        </div>
                    </div>
                </div>

                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={n5} alt="Imagen de la noticia" className='newsImage' />
                    </div>

                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Alumnos del ITSPA junto con CA2 ITSPA-CA-2, van al CBTIS94</h2>
                        <p className='parrafoF'>Fecha: 08/02/2024</p>

                        <p className='parrafosNA colortxt3'>
                            ¡Estudiantes del Tec de Pátzcuaro! A través del CA2 ITSPA-CA-2 "Gestión de Sistemas de
                            Innovación y Desarrollo Territorial" y el programa ITSPAOrienta, llevamos a cabo un
                            emocionante Taller Lúdico sobre Economía Internacional en el CBTis#94 "Emiliano Zapata".
                            ¡Una experiencia divertida y educativa donde exploramos juntos los conceptos clave de la
                            economía global!
                        </p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticia5}>Leer más...</button>
                        </div>
                    </div>

                </div>

                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={n4} alt="Imagen de la noticia" className='newsImage' />
                    </div>
                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Manual para la conformación</h2>
                        <p className='parrafoF'>Fecha: 18/01/2024</p>

                        <p className='parrafosNA colortxt3'>
                            ¿Cómo generar una cooperativa de consumo? ¿La organización para la compra conglomerada
                            es posible? ¿Qué opciones tengo como pequeño productor? Con el "Manual para la Conformación
                            de Cooperativas de Consumo"
                        </p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticia4}>Leer más...</button>
                        </div>
                    </div>

                </div>
                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={n3} alt="Imagen de la noticia" className='newsImage' />
                    </div>
                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Reunión con las Cooperativas</h2>
                        <p className='parrafoF'>Fecha: 12/01/2024</p>

                        <p className='parrafosNA colortxt3'> El Instituto Tecnológico Superior de Pátzcuaro,
                            el Nodess Patzcuaro -Zirahuén y la Red Regional de Economía Social Solidaria
                            en Michoacán, con el propósito de compartir experiencias en cooperativismo e
                            impulsar la economía social y solidaria, llevaron a cabo el 1er. Encuentro de
                            cooperativistas y los Nodos de impulso a la Economía social solidaria, con la
                            valiosa participación de la Sociedad cooperativa trabajadores de Pascual S.C.L.
                            quienes compartieron su larga trayectoria y lucha por posicionarse como una
                            empresa mexicana de alto nivel y que ha permanecido a lo largo de más de 30
                            años como cooperativa. De igual forma se contó con la presencia de colectivos
                            de las comunidades de Janitzio, Tzurumutaro, Nocutzepo, San Jerónimo Purenchecuaro
                            y de Paracho. Así como de autoridades, docentes y alumnos de  distintas instituciones
                            educativas de Morelia y la región. En el Nodess Patzcuaro-Zirahuén vamos paso a paso,
                            por ello es que continuamos avanzando. Trabajando siempre juntos, para que la economía
                            social y solidaria nunca se detenga.</p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticia3}>Leer más...</button>
                        </div>
                    </div>
                </div>

                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={n2} alt="Imagen de la noticia" className='newsImage' />
                    </div>
                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Manuel para la conformación de cooperativas de consumo</h2>
                        <p className='parrafoF'>Fecha: 11/01/2024</p>

                        <p className='parrafosNA colortxt3'>¡Comienza el año capacitandote! INAES tiene para ti el
                            "Manual para la Conformación de Cooperativas de Consumo".
                            Donde encontrarás las herramientas necesarias para que en grupo crees y formalizes
                            tus actividades de consumo colectivo.</p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticia2}>Leer más...</button>
                        </div>
                    </div>
                </div>

                <div className="noticias-container">
                    <div className='news-content-img'>
                        <img src={noticia1} alt="Imagen de la noticia" className='newsImage' />
                    </div>
                    <div className='news-content-inf'>
                        <h2 className='tituloNoticia'>Nuevos programas</h2>
                        <p className='parrafoF'>Fecha: 11/01/2024</p>

                        <p className='parrafosNA colortxt3'>Tecnológico de Pátzcuaro proyecta abrir dos nuevos programas de estudio</p>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClicknoticia1}>Leer más...</button>
                        </div>
                    </div>
                </div>

            </section>


        </div>
    );
}

const handleButtonClicknoticia1 = () => {
    window.location.href = 'https://www.facebook.com/share/p/dgbSRMkCJPpYAVWi/?mibextid=oFDknk';
};
const handleButtonClicknoticia2 = () => {
    window.location.href = 'https://www.facebook.com/share/p/7u44jZmzpCqBL2Bs/?mibextid=oFDknk';
};
const handleButtonClicknoticia3 = () => {
    window.location.href = 'https://www.facebook.com/share/p/dYUwuJEtJHh6pbZv/?mibextid=oFDknk';
};
const handleButtonClicknoticia4 = () => {
    window.location.href = 'https://www.facebook.com/share/p/2BpReHdJKyxE2xBB/?mibextid=oFDknk';
};
const handleButtonClicknoticia5 = () => {
    window.location.href = 'https://www.facebook.com/share/p/qzREQDFpXN7W3Y7K/?mibextid=oFDknk';
};
const handleButtonClicknoticia6 = () => {
    window.location.href = 'https://www.facebook.com/share/p/8bPHeeXp2HBHb6X3/?mibextid=oFDknk';
};
const handleButtonClicknoticia7 = () => {
    window.location.href = 'https://www.facebook.com/share/p/71m2C1hWafxzjbkM/?mibextid=oFDknk';
};
const handleButtonClicknoticiaA2 = () => {
    window.location.href = 'https://www.facebook.com/share/p/DoTjNeEftdokQFCj/?mibextid=oFDknk';
};
const handleButtonClicknoticiaA3 = () => {
    window.location.href = 'https://www.facebook.com/share/p/2jZSqgpE8UFKfXCi/?mibextid=oFDknk';
};
const handleButtonClicknoticiaA4 = () => {
    window.location.href = 'https://www.facebook.com/share/p/WDDCi2UuKhs6VN7c/?mibextid=oFDknk';
};

const handleButtonClicknoticiaultima = () => {
    window.location.href = 'https://www.facebook.com/share/p/R87DX6cqPRDuwL5G/?mibextid=oFDknk';
};
const handleButtonClicknoticiaCongreso_2504 = () => {
    window.location.href = 'https://www.facebook.com/share/p/xt86VF5nVMA9xjNa/?mibextid=oFDknk';
};
const handleButtonClicknoticiaTaller2404 = () => {
    window.location.href = 'https://www.facebook.com/share/p/M9efHKGXYHJWmfmC/?mibextid=oFDknk';
};
const handleButtonClicknoticiaIso2504 = () => {
    window.location.href = 'https://www.facebook.com/share/p/Xrh9kBY4BnUjCahp/?mibextid=oFDknk';
};
const handleButtonClicknoticiaConvocatoria2304 = () => {
    window.location.href = 'https://www.facebook.com/share/p/WRTB7iBnKHnMUXak/?mibextid=oFDknk';
};
const handleButtonClicknoticiaInaguracion_2604 = () => {
    window.location.href = 'https://www.facebook.com/share/p/xt86VF5nVMA9xjNa/?mibextid=oFDknk';
};
export default NewsList;