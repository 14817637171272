import React, { useState } from "react";

import { Link, Outlet } from 'react-router-dom';
import "../../components/cardStyles.css"
import "../../styles/btnMenu.css"


function LineasEstrategicas() {
    return (
        <div className='container'>
                        <h2 className='tituloPagina tituloCentrado'>Lineas Estratégicas</h2>

            <div class="menu-container">
                <ul class="menu-list">
                    <li>
                        <Link to="DivulgacionDifusion">Divulgación y Difusión</Link>
                    </li>
                    <li>
                        <Link to="CulturaPaz">Cultura de Paz</Link>
                    </li>
                    <li>
                        <Link to="EstrategiaFortalecimiento">Estrategia De Fortalecimiento De Capacidades</Link>
                    </li>
                    <li>
                        <Link to="EmprendimientosColect">Emprendimientos Colectivos</Link>
                    </li>
                    <li>
                        <Link to="Investigacion">Investigación</Link>
                    </li>
                </ul>
            </div>
            <Outlet />

          
        </div >
    );
}





export default LineasEstrategicas;