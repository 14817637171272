import React from 'react'
import "../../../src/styles/generalStyles.css";
import "../inicio/inicio.css";
import "../../../src/styles/BannerStyle"

import pie from "../../Imagenes/alianzas/pies Agiles.jpeg"
import bienestar from "../../Imagenes/alianzas/Bienestar.jpg"
import ario from "../../Imagenes/alianzas/ariosoales.jpeg"
import crs from "../../Imagenes/alianzas/CRS_2.jpeg"
import eronga from "../../Imagenes/alianzas/eronga.jpeg"
import patzcuaro from "../../Imagenes/alianzas/patzcuaro.png"
import tzintzuntzan from "../../Imagenes/alianzas/tzintzuntzan.png"
import EE from "../../Imagenes/alianzas/emprendedor.png"
import SADR from "../../Imagenes/alianzas/Agricultura.jpeg"
import migrante from "../../Imagenes/alianzas/migrante.jpeg"

function Alianzas() {
    return (
        <div className="container">
            <h1 className="tituloPagina ">Alianzas</h1>

            <section className="section2 ">

                <p className='parrafosNA'>
                    Los NODESS son alianzas locales, voluntarias y autónomas, interesadas en fomentar e implementar acciones concretas de Economía Social y Solidaria (ESS) en el territorio.
                    Las alianzas NODESS se integran, por lo menos, con tres actores representativos de los sectores económico social, académico y gubernamental, como lo son:
                </p>

                <div className='contenedor-padre'>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={crs} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>CRS</h3>
                            <p className='parrafosNA'>
                                Catholic Relief Services en México trabaja para mejorar la calidad de vida de las
                                personas vulnerables y marginadas, así como para promover el desarrollo sostenible
                                y la justicia social en el país.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickCRS}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={EE} alt="Icono 3" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Espacio Emprendedor</h3>
                            <p className='parrafosNA'>Programa que forma parte del gobierno del estado de Michoacán,
                             ubicado en la Avenida Lázaro Cárdenas de la ciudad de Morelia. Su principal objetivo de 
                             este organismo es fortalecer a las empresas, mediante estrategias de incubación, asesoría
                              de registro de marcas y patentes.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickEmp}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={SADR} alt="Icono 3" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Secretaría de Agricultura, Desarrollo Rural y Alimentario</h3>
                            <p className='parrafosNA'>
                                Es la entidad encargada de formular y ejecutar políticas, programas y proyectos relacionados
                                con el desarrollo del sector agrícola, rural y alimentario en el estado de Michoacán.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickAgric}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={bienestar} alt="Icono 3" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Secretaría del Bienestar</h3>
                            <p className='parrafosNA'>
                                Es una iniciativa gubernamental que busca mejorar las condiciones de vida de la
                                población michoacana, especialmente de aquellos en situación de vulnerabilidad.
                                Este programa abarca diversas áreas, como salud, educación, vivienda, alimentación,
                                empleo y desarrollo social.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickBie}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={migrante} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Secretaría del Migrante</h3>
                            <p className='parrafosNA'>
                                Este programa tiene como objetivo brindar apoyo y atención integral a los
                                migrantes michoacanos, así como a sus familias, tanto en el estado como
                                en el extranjero. Se enfoca en ofrecer servicios que abarcan desde la
                                asistencia legal y la protección de los derechos humanos.
                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickMigra}>Conocer más</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={pie} alt="Icono 1" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Pies Agiles</h3>
                            <p className='parrafosNA'>

                            </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickPie}>Conocer más</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={ario} alt="Icono 1" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Ayuntamiento de Ario</h3>
                            <p className='parrafosNA'> 
                            El Ayuntamiento de Ario de Rosales es la autoridad municipal encargada de administrar y 
                            gobernar el municipio de Ario de Rosales. Su sitio web puede ofrecer información sobre 
                            servicios municipales, trámites administrativos, noticias y eventos locales, así como 
                            datos relevantes sobre la gestión y el gobierno municipal.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickAri}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={eronga} alt="Icono 3" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Ayuntamiento de Erongaricuaro</h3>
                            <p className='parrafosNA'>
                            Sitio web para encontrar información sobre servicios municipales, 
                            trámites administrativos, 
                            noticias locales, eventos comunitarios, proyectos de desarrollo, así como datos históricos, 
                            culturales y turísticos sobre Erongarícuaro.  </p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickEro}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={patzcuaro} alt="Icono 2" className='icon' />
                        </div>

                        <div class="card-content">
                            <h3 className='tituloNoticia'>Ayuntamiento de Patzcuaro</h3>
                            <p className='parrafosNA'>
                            Sitio web para encontrar información sobre servicios municipales, 
                            trámites administrativos, 
                            noticias locales, eventos comunitarios, proyectos de desarrollo, así como datos históricos, 
                            culturales y turísticos sobre Pátzcuaro.</p>
                        </div>

                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickPat}>Conocer más...</button>
                        </div>
                    </div>
                    <div className='imageSubContainer color9'>
                        <div className='imageCardContainer'>
                            <img src={tzintzuntzan} alt="Icono 3" className='icon' />
                        </div>
                        <div class="card-content">
                            <h3 className='tituloNoticia'>Ayuntamiento de Tzintzunzan</h3>
                            <p className='parrafosNA'> 
                            Sitio web para encontrar información sobre servicios municipales, 
                            trámites administrativos, 
                            noticias locales, eventos comunitarios, proyectos de desarrollo, así como datos históricos, 
                            culturales y turísticos sobre Tzintzunzan.</p>
                        </div>
                        <div className='buttonContainer'>
                            <button className='bn3637 bn37' onClick={handleButtonClickTzi}>Conocer más...</button>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );

}
const handleButtonClickPie = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = '';
};

const handleButtonClickMigra = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://migrante.michoacan.gob.mx/';
};
const handleButtonClickAgric = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://sader.michoacan.gob.mx/';
};
const handleButtonClickEmp = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = '';
};
const handleButtonClickBie = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://bienestar.michoacan.gob.mx/';
};
const handleButtonClickCRS = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.crsespanol.org/donde-servimos/america-latina-y-el-caribe/mexico/';
};
const handleButtonClickPat = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://patzcuaro.gob.mx/';
};
const handleButtonClickTzi = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://tzintzuntzan.gob.mx/';
};
const handleButtonClickEro = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://erongaricuaro.gob.mx/';
};
const handleButtonClickAri = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.location.href = 'https://www.ario.gob.mx/';
};
export default Alianzas;