import React from 'react'
import "../../../src/styles/generalStyles.css";
import "../../components/cardStyles.css"
import "../../styles/btnMenu.css"


function CulturaPaz() {
    return (
        <div className='container'>

            <section className='section2' id='cultura'>
                <h1 className='tituloSeccion separador'>Cultura de Paz</h1>
                <p className='parrafosNA'>
                    Son Acciones que comuniquen y promuevan a la Economía Social y Solidaria,
                    sus principios, valores y prácticas,.
                </p>
            </section>
        </div>
    );
}



export default CulturaPaz;