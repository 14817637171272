import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/generalStyles.css";
import DraGTC from '../../Imagenes/Nosotros/Integrantes/DraGTC.png'

function Member2Info() {
    return (
        /**Seccion  */
        <div className='container'>
            <h2 className="h1 tituloCentrado">Sobre mi</h2>

            <section className='section'>
                <div className='imageContainer'>

                    <img src={DraGTC} alt="Imagen de la noticia" className='newsImage' />
                    <p className='justifiedText pStyle'>Dra. Ma. Guadalupe Torres Chávez</p>
                    <div>
                        <h3 className="h1 tituloCentrado ">Formación Académica</h3>

                        <p className='justifiedText pStyle'>La docente investigadora del Tecnológico Nacional 
                        de México campus Pátzcuaro es licenciada en Derecho y tiene una Maestría en Derecho
                         por la Facultad de Derecho y Ciencias Sociales. También cuenta con un Doctorado en
                          Ciencias del Desarrollo Regional por el Instituto de Investigaciones Económico 
                          Empresariales de la Universidad Michoacana de San Nicolás de Hidalgo (UMSNH).</p>
                    </div>
                </div>
                <div className='textContainer' >
                    <h2 className="h1 tituloCentrado ">Logros</h2>
                    <p className='justifiedText pStyle'>Es miembro y líder del Cuerpo Académico en formación 
                    ITSPA-CA-2 Gestión de sistemas de innovación y desarrollo territorial, y tiene reconocimiento 
                    a perfil deseable por el PRODEP. Además, es miembro del directorio de investigadores y líder 
                    de la red de investigación "Vinculación social e intervención en la comunidad" del Programa 
                    Delfín. También forma parte del equipo operativo del NODESS Pátzcuaro Zirahuén con registro 
                    SINCA_PNSS_20_00146_INAES.
                    </p>
                    <h2 className="h1 tituloCentrado ">Experiencias</h2>

                    <p className='justifiedText pStyle'>La docente ha colaborado en el proyecto 320510 
                    Pronace-Conacyt y ha publicado diversos artículos y capítulos de libro arbitrados e 
                    indexados. Como docente, imparte asignaturas en el área legal y de investigación científica
                     en la currícula de la Ingeniería en Administración. Además, es asesora del Centro de 
                     Incubación e Innovación Empresarial del ITSPA (CIIE-ITSPA).
                    </p>


                    <div>
                        <h2 className="h1 tituloCentrado ">Contacto</h2>
                        <p className='justifiedText pStyle'>gtorres@itspa.edu.mx</p>
                    </div>
                </div>
            </section>
        </div>

    )
}



export default Member2Info;