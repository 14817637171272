import React from 'react'
import "../../../src/styles/generalStyles.css";
import tec from '../../Imagenes/Contactanos/tec.jpg'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/cardsStyles.css";
import "../../../src/styles/cardsStylesEqOperativo.css";
import EquipoOperativo from "../../Imagenes/Nosotros/Integrantes/EquipoOperativo";
import ojo from '../../Imagenes/Logos/ojo.png'


function Contactanos() {

    // Función para abrir la ubicación en Google Maps al hacer clic en el enlace
    const openMap = () => {
        //const coordinates = '19.5410924,-101.5839984'; // Coordenadas de la ubicación en Google Maps
        window.open('https://www.google.com/maps/place/Instituto+Tecnol%C3%B3gico+Superior+De+P%C3%A1tzcuaro/@19.5410924,-101.5839984,15z/data=!4m13!1m6!3m5!2zMTnCsDMyJzIwLjIiTiAxMDHCsDM1JzA4LjYiVw!8m2!3d19.5389444!4d-101.5857222!10e5!3m5!1s0x842d96443815d9c1:0x9cc096bc1fcd5152!8m2!3d19.5410924!4d-101.5839984!16s%2Fg%2F11g889p0qs?entry=ttu', '_blank');
    };

    return (
        <div className='container'>

            <h1 className="tituloPagina tituloCentrado">Contactanos</h1>
            <div>
                <h3 className="h1 tituloCentrado ">Ubicación</h3>
                <p className='justifiedText pStyle' style={{ textAlign: 'center' }}>
                    <a href="#" onClick={openMap} style={{ color: 'inherit', textDecoration: 'none' }}>
                        Av. Tecnológico No. 1, Zurumútaro, Mpio. de Pátzcuaro, Mich., Carretera Pátzcuaro-Morelia, Pátzcuaro, Mexico
                    </a>
                </p>
            </div>
            <section className='section2'>
                <div className='contenedor-padre'>
                    <div >
                        <div class="info-contacto">
                            <h3 className="h1 tituloCentrado ">Teléfono</h3>
                            <p className='justifiedText pStyle' style={{ textAlign: 'center' }}>
                            434 542 50 49 </p>
                        </div>
                        <div>
                            <h3 className="h1 tituloCentrado ">Gmail</h3>
                            <p className='justifiedText pStyle' style={{ textAlign: 'center' }}>
                                <a href="mailto:nodess.patz.zir@itspa.edu.mx" style={{ color: 'inherit' }}>nodess.patz.zir@itspa.edu.mx</a>
                            </p>
                        </div>
                        <div>
                            <h3 className="h1 tituloCentrado ">Facebook</h3>
                            <p className='justifiedText pStyle' style={{ textAlign: 'center' }}>
                                <a href="https://www.facebook.com/profile.php?id=100050519454292" style={{ color: 'inherit', textDecoration: 'none' }}>Nodess Pátzcuaro-Zirahuén</a>

                            </p>
                        </div>

                    </div>
                    <div className='contenedor-padre'>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={tec} alt="Icono 1" className='icon' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>          
        
            <section className="section2 color3">
                <div className="tituloCentrado">
                    <h2 className="tituloPagina colorSeccionLetra">Equipo Operativo</h2>
                </div>

                <div className="sectionEO">


                    <button className='bnCard bnCardColor cardEO ' onClick={handleButtonClickLety}>
                        <img src={EquipoOperativo.leticia} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">MC. Leticia Oseguera Figueroa</h2>
                            <p class="card-description">Licenciada en Biología por la Facultad de Biología de la UMSNH.</p>
                        </div>
                    </button>

                    <button className='bnCard bnCardColor cardEO ' onClick={handleButtonClickSoraida}>
                        <img src={EquipoOperativo.soraida} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">Dra. Soraida Quezada Ascencio</h2>
                            <p class="card-description">Docente Investigadora del Instituto Tecnológico Superior de Pátzcuaro</p>
                        </div>
                    </button>
                    <button className='bnCard bnCardColor cardEO'  onClick={handleButtonClickSergio} >
                        <img src={EquipoOperativo.sergio} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">Mtro. Sergio Govea Valdez</h2>
                            <p class="card-description">Docente e Investigador del Instituto Tecnologio Superior de Pátzcuaro</p>
                        </div>
                    </button>
                    <button className='bnCard bnCardColor cardEO'  onClick={handleButtonClickchristian}>
                        <img src={EquipoOperativo.christian} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">Mtro. Christian Omar Pérez Díaz</h2>
                            <p class="card-description">Docente e Investigador del Instituto Tecnologio Superior de Pátzcuaro</p>
                        </div>
                    </button>
                    <button className='bnCard bnCardColor cardEO '  onClick={handleButtonClickMiriam}>
                        <img src={EquipoOperativo.miriam} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">Dra. Miriam E. Aguirre Arias</h2>
                            <p class="card-description">Docente Investigadora del Tecnológico Superior de Pátzcuaro</p>
                        </div>
                    </button>
                    <button className='bnCard bnCardColor cardEO '  onClick={handleButtonClickGuadalupe}>
                        <img src={EquipoOperativo.DraGTC} alt="Imagen" className='iconCard' />
                        <div className='overLay-textCard'>
                            <h2 class="card-title">Dre. Ma. Guadalupe Torres Chávez</h2>
                            <p class="card-description">Docente e Investigador del Instituto Tecnologio Superior de Pátzcuaro</p>
                        </div>
                    </button>
                </div>

            </section>

        </div>
    );
}
const handleButtonClickLety = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member1Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
const handleButtonClickGuadalupe = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member2Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
const handleButtonClickSergio = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member3Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
const handleButtonClickchristian = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member4Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
const handleButtonClickSoraida = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member5Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};
const handleButtonClickMiriam = () => {
    // Redirige a la URL especificada cuando se hace clic en el botón
    window.history.pushState(null, null, '/Member6Info');
    // Emitir un evento "popstate" para que el navegador trate la URL actualizada
    window.dispatchEvent(new Event('popstate'));
};

export default Contactanos;