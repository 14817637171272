import React, { useState } from "react";

import { Link, NavLink } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../components/NavBar.css"
import logoNodess from '../Imagenes/Logos/logoNodess.png'

const links = [
    {
        name: "Inicio",
        href: "/Inicio"
    },
    {
        name: "Nosotros",
        href: "/Nosotros"
    },
    {
        name: "Noticas",
        href: "/Noticias"
    },
    {
        name: "Lineas Estrategicas",
        href: "/LineasEstrategicas"
    },
    {
        name: "Alianzas",
        href: "/Alianzas"
    },
    {
        name: "Osses",
        href: "/Osses"
    },
    {
        name: "Contactanos",
        href: "/Contactanos"
    },
]



const NavBar = () => {


    const [menuOpen, setMenuOpen] = useState(false);


    return (
        <nav>
            <Link className="logo">
                <img
                    src={logoNodess}
                    alt="Logo"
                    className="logoImage"
                />
            </Link>

            <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <ul className={menuOpen ? "open" : ""}>
                {links.map((x, index) => (
                    <li key={index}>
                        <NavLink to={x.href}>
                            {x.name}
                        </NavLink>
                    </li>
                ))}
            </ul>

        </nav>


    );

}


export default NavBar;