import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../src/styles/generalStyles.css";
import miri from '../../Imagenes/Nosotros/Integrantes/miriam.jpg'

function Member6Info() {
    return (
        /**Seccion  */
        <div className='container'>
            <h2 className="h1 tituloCentrado">Sobre mi</h2>

            <section className='section'>
                <div className='imageContainer'>

                    <img src={miri} alt="Imagen de la noticia" className='newsImage' />
                    <p className='justifiedText pStyle'>Dra. Miriam E. Aguirre Arias</p>
                    <div>
                        <h3 className="h1 tituloCentrado ">Formación Académica</h3>

                        <p className='justifiedText pStyle'>Es licenciada en Contaduría por la Universidad Michoacana
                            de San Nicolás de Hidalgo (UMSNH), maestra en Innovación para el Desarrollo Empresarial por
                            el ITESM campus Morelia y doctora en Educación por la Universidad de Baja California. Además,
                            posee una certificación de CONOCER para la Formulación del Diseño de Proyectos de Inversión
                            Rural ECO020.</p>
                    </div>
                </div>
                <div className='textContainer' >
                    <h2 className="h1 tituloCentrado ">Logros</h2>
                    <p className='justifiedText pStyle'>Es miembro del cuerpo académico en formación ITSPA-CA-2
                        Gestión de Sistemas de Innovación y Desarrollo Territorial y forma parte de la red de
                        investigación "Vinculación social e intervención en la comunidad," del Programa Delfín.
                        También es la coordinadora del NODESS Pátzcuaro-Zirahuén.
                    </p>
                    <h2 className="h1 tituloCentrado ">Experiencias</h2>

                    <p className='justifiedText pStyle'>El docente ha colaborado como parte del Evento Nacional Estudiantil
                        de Innovación Tecnológica (ENEIT) en sus etapas nacional y regional en 2016-2017, y ha sido
                        jurado regional y nacional en eventos como ENEIT e InnovaTecNM.
                        <br/>
                        Como docente, ha impartido asignaturas en el área económico-administrativa, tales como contabilidad 
                        orientada a negocios, finanzas en las organizaciones, plan de negocios, desarrollo de emprendedores 
                        y desarrollo humano.
                    </p>


                    <div>
                        <h2 className="h1 tituloCentrado ">Contacto</h2>
                        <p className='justifiedText pStyle'>maguirre@itspa.edu.mx</p>
                    </div>
                </div>
            </section>
        </div>

    )
}



export default Member6Info;