import React from 'react'
import "../../../src/styles/generalStyles.css";
import "../../components/cardStyles.css"
import "../../styles/btnMenu.css"
import cede from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/CELE.jpg'
import contenido_1 from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/CONTENIDOS GRÁFICOS_.jpg'
import contenido_2 from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/CONTENIDOS GRÁFICOS_2.jpg'
import contenido_3 from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/CONTENIDOS GRÁFICOS_3.jpg'
import diseño_temporada from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/diseño_grafico_navideño.jpg'
import seguimientoCobertura from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/CoberturaSeguimientoActividades.jpg'
import mujeres_1 from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/EncuentroMujeresCooperativas_1.jpg'
import mujeres_2 from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/EncuentroMujeresCooperativas_2.jpg'
import foro_1 from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/Foro_1.jpg'
import foro_2 from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/Foro_2.jpg'
import economia from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/ForoEconomiaSocial.jpg'
import vinculacion_1 from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/VinculacionDireccion_1.jpg'
import vinculacion_2 from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/VinculacionDireccion_2.jpg'
import intercambioExpresion from '../../Imagenes/LineasEstrategias/Divulgacion_Difusión/IntercambioExperenciasValor.jpg'


function DivulgacionDifusion() {
    return (
        <div className='container'>

            <section className='section2' id='divulgacion'>
                <h1 className='tituloSeccion separador'>Divulgación y Difusion</h1>
                <p className='parrafosNA'>
                    Son Acciones que comuniquen y promuevan a la Economía Social y Solidaria,
                    sus principios, valores y prácticas,.
                </p>
            </section>

            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>COBERTURA DEL CONGRESO CIEDE</h1>
                </div>

                <p className='parrafosNA'>
                    Cobertura en el congreso CIEDE, destacando la participación del NODESS Pátzcuaro-Zirahuén en el evento, esto para generar contenido que sirva para la difusión y
                    posicionamiento.
                </p>

                <div className='newsImageContainer'>
                    <img src={cede} alt="Imagen de la noticia" className='newsImage' />
                </div>

            </section>

            <section className='section2 color5'>
                <div >
                    <h1 className='tituloDerecha separador h2'>CONTENIDOS GRÁFICOS QUE PARA LA PROMOCIÓN DEL NODESS
                        PÁTZCUARO-ZIRAHUÉN.</h1>
                </div>
                <div className='contenedor-padre'>
                    <div>
                        <div className='newsImageContainer'>
                            <img src={contenido_1} lt="Imagen de la noticia" className='newsImage' />
                        </div>
                    </div>
                    <div>
                        <div className='newsImageContainer'>
                            <img src={contenido_2} alt="Imagen de la noticia" className='newsImage' />
                        </div>
                    </div>
                    <div>
                        <div className='newsImageContainer'>
                            <img src={contenido_3} alt="Imagen de la noticia" className='newsImage' />
                        </div>
                    </div>
                </div>

            </section>



            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>DISEÑO DE IDENTIDAD GRÁFICA DE TEMPORADA</h1>
                </div>

                <p className='parrafosNA'>
                    Se ha creado una identidad gráfica coherente y atractiva para cada temporada de
                    actividades del Nodess Pátzcuaro-Zirahuén, lo que ha contribuido a fortalecer la imagen
                    institucional y a generar mayor interés y participación por parte del público objetivo.
                </p>

                <div className='newsImageContainer'>
                    <img src={diseño_temporada} alt="Imagen de la noticia" className='newsImage' />
                </div>

            </section>



            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>COBERTURA Y SEGUIMIENTO PIRAMIDAL DE LAS ACTIVIDADES</h1>
                </div>

                <p className='parrafosNA'>
                    Se ha establecido un sistema de cobertura y seguimiento piramidal de las actividades del
                    Nodess Pátzcuaro-Zirahuén, lo que ha permitido una comunicación efectiva y una
                    coordinación fluida con instituciones de referencia como el INAES, SEMARNAT, el
                    Ayuntamiento de Pátzcuaro, entre otras. Esto ha facilitado la difusión de las actividades del
                    Nodo y ha fortalecido las alianzas estratégicas con estas instituciones.
                    <br />
                    Alcance: Incremento del 30% en el alcance orgánico de las publicaciones en Facebook
                    durante el último trimestre.
                    <br />
                    Participación: Aumento del 50% en la interacción (me gusta, comentarios, compartidos)
                    con el contenido publicado en la página de Facebook del Nodess Pátzcuaro-Zirahuén en
                    comparación con el trimestre anterior.
                    <br />
                    Cobertura de Eventos: Cobertura y seguimiento de al menos el 80% de las actividades
                    realizadas en colaboración con instituciones como el INAES, SEMARNAT y el Ayuntamiento
                    de Pátzcuaro, garantizando una mayor visibilidad y relevancia del Nodo en la región.
                </p>

                <div className='newsImageContainer'>
                    <img src={seguimientoCobertura} alt="Imagen de la noticia" className='newsImage' />
                </div>

            </section>

            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>ASISTENCIA A FOROS</h1>
                </div>

                <div className='contenedor-padre'>
                    <div className='imageSubContainer'>
                        <h3 className='tituloCentrado'>Desafíos para el impulso de la economía social solidaria y el cooperativismo en
                            Michoacán</h3>
                        <p className='parrafosNA'>
                            Organizado por la facultad de economía de la Universidad Michoacana de San Nicolás de
                            Hidalgo, desarrollado el día 4 de octubre en las instalaciones del auditorio de dicha facultad,
                            en la mesa de trabajo titulada: “Hacia un Ecosistema Regional de Cooperativismo y
                            Economía Social Solidaria”, estuvieron presentes autoridades del INAES, SADER, Uniones
                            de cooperativas, ITESO y otras instituciones que coincidieron en este foro en donde se
                            fomentó la cultura de la economía social solidaria.
                        </p>
                        <div>
                            <div>
                                <img src={foro_1} alt="Icono 1" className='icon' />
                                <img src={foro_1} alt="Icono 1" className='icon' />
                            </div>
                            <div>
                                <img src={foro_2} alt="Icono 1" className='icon' />
                                <img src={foro_2} alt="Icono 1" className='icon' />
                            </div>
                        </div>



                    </div>
                    <div className='imageSubContainer'>
                        <h3 className='tituloCentrado'>Encuentro de mujeres cooperativistas</h3>
                        <p className='parrafosNA'>
                            En el marco del encuentro de mujeres cooperativistas organizado por la Facultad de
                            economía de la Universidad Michoacana de San Nicolás de Hidalgo(UMSNH) y la Secretaría
                            de Agricultura y Desarrollo Rural (SADER), el Nodess Pátzcuaro - Zirahuén. estuvo
                            representado por la cooperativa "Jak quechas Urichas" y Tsitsiki Laure realizando una
                            importante participación para visibilizar los esfuerzos que han venido realizando para
                            consolidarse como colectivo e invitar a más mujeres a confiar en sus capacidades y
                            sumarse a contribuir por una economía más justa.
                        </p>
                        <div>
                            <div>
                                <img src={mujeres_1} alt="Icono 1" className='icon' />
                                <img src={mujeres_1} alt="Icono 1" className='icon' />
                            </div>
                            <div>
                                <img src={mujeres_2} alt="Icono 1" className='icon' />
                                <img src={mujeres_2} alt="Icono 1" className='icon' />
                            </div>
                        </div>



                    </div>
                    <div className='imageSubContainer'>
                        <h3 className='tituloCentrado'>1er. Foro de economía social solidaria de la Piedad</h3>
                        <p className='parrafosNA'>
                            4 de octubre de 2023. En el segundo día de actividades del Primer Foro de Economía Social
                            y Solidaría que el Consejo Michoacano para la Construcción de la Paz y la Reconciliación y
                            el Tecnológico Nacional de México a través de sus Campus Instituto Tecnológico de La
                            Piedad e Instituto Tecnológico de Morelia llevaron a cabo los días 3 y 4 de octubre del
                            actual, se presentó la Conferencia Presencial: "La Economía Social y El Emprendimiento
                            Asociativo Innovador" participando como Ponente la Lic. Esmeralda Guadalupe Betancio
                            Guerrero, Jefa de Departamento en el Instituto Nacional de la Economía Social. Una vez
                            concluida esta primera conferencia, del día, se tuvo el Foro: Experiencias de ESS en
                            Michoacán, teniendo como escenario el Gimnasio Auditorio “Ing. Bertín Cornejo Cruz”, y
                            como participantes expositores integrantes de los NODESS IT La Piedad; NODESS IT
                            Pátzcuaro y Proyecto Reboceros de La Piedad.
                        </p>
                        <img src={economia} alt="Icono 1" className='icon' />

                    </div>
                </div>

            </section>



            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>VINCULACIÓN CON LA DIRECCIÓN DE DESARROLLO ECONÓMICO
                        DEL MUNICIPIO DE PÁTZCUARO.</h1>
                </div>

                <p className='parrafosNA'>
                    Reunión en las instalaciones del Instituto Tecnológico Superior de Pátzcuaro, con los
                    integrantes del equipo operativo del NODESS Pátzcuaro Zirahuén y representantes de la
                    SADER regional con el Lic. Jorge Lemus Cervantes, director de Desarrollo Económico de
                    Pátzcuaro, con la finalidad de desarrollar programas de fortalecimiento a emprendedores
                    de la economía social solidaria en beneficio de la ciudadanía. En el Nodess Patzcuaro-Zirahuén vamos paso a paso, por ello es por lo que continuamos avanzando.
                </p>
                <div className='contenedor-padre'>
                    <div>
                        <div className='newsImageContainer'>
                            <img src={vinculacion_1} alt="Imagen de la noticia" className='newsImage' />
                        </div>
                    </div>
                    <div>
                        <div className='newsImageContainer'>
                            <img src={vinculacion_2} alt="Imagen de la noticia" className='newsImage' />
                        </div>
                    </div>
                </div>


            </section>

            <section className='section2 color5'>
                <div>
                    <h1 className='tituloDerecha separador h2'>PARTICIPACIÓN EN LA REUNIÓN NACIONAL DE NODESS CON LA
                        INICIATIVA “INTERCAMBIO DE EXPERIENCIAS DE VALOR” VÍA ZOOM.</h1>
                </div>


                <div className='newsImageContainer'>
                    <img src={intercambioExpresion} alt="Imagen de la noticia" className='newsImage' />
                </div>

            </section>




            
        </div >
    );
}





export default DivulgacionDifusion;